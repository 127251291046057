

export const LogoIcon = () => {
  return (
      <svg width="63" height="74" viewBox="0 0 63 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id={"fill_color"} d="M37.5695 25.4435C37.6284 25.5664 37.6774 25.6795 37.7216 25.7877L38.7025 28.246C38.9954 28.9756 39.1437 29.7555 39.139 30.542C39.1356 31.36 38.993 32.1715 38.7172 32.9413C38.2486 34.2366 37.4999 35.4119 36.5248 36.383C34.3758 38.5217 31.4751 39.7293 28.4469 39.7459C26.8071 39.7646 25.1914 39.349 23.763 38.5414C23.1318 38.1763 22.561 37.7152 22.0709 37.1745C21.61 36.6501 21.2432 36.0496 20.987 35.3996L20.006 32.9413C19.9668 32.8381 19.9227 32.7201 19.8785 32.5923L16.0431 33.9001C16.1019 34.0722 16.1706 34.259 16.2491 34.4557L17.23 36.914C17.6716 37.9948 18.2936 38.9924 19.0692 39.8639C20.2243 41.1573 21.6608 42.1666 23.2676 42.8139C24.9135 43.4783 26.6726 43.8156 28.4469 43.8071C30.4175 43.8025 32.3689 43.4186 34.1951 42.6762C36.7803 41.655 39.0342 39.9384 40.7085 37.7154C41.487 36.6748 42.1054 35.5229 42.5428 34.2983C42.9736 33.0946 43.1958 31.8258 43.2 30.5469C43.2062 29.2417 42.9597 27.9477 42.4742 26.7366L41.4932 24.2783C41.4148 24.0865 41.3363 23.9046 41.2578 23.7374L37.5695 25.4435Z" fill="black"/>
            <path id={"fill_color"} d="M13.1493 36.147H13.3799C13.6937 36.147 14.3068 36.147 15.0818 36.0733C15.6654 36.0241 16.3423 35.9454 17.0436 35.8127C18.2137 35.6109 19.3496 35.2452 20.418 34.7261C21.0173 34.4378 21.5737 34.0671 22.0709 33.6248C22.6014 33.1386 23.0285 32.5499 23.3264 31.8942L19.6185 30.2274C19.5533 30.364 19.4635 30.4873 19.3537 30.5912C19.0038 30.9094 18.5934 31.1536 18.1472 31.3091C17.2551 31.6437 16.3237 31.8618 15.376 31.9581C14.7136 32.0391 14.0471 32.0818 13.3799 32.0859H13.2425L13.1395 36.1421L13.1493 36.147Z" fill="black"/>
            <path id={"fill_color"} d="M35.0435 29.0081C35.0828 29.0081 35.534 28.9245 36.255 28.7475C36.7896 28.6148 37.4517 28.4329 38.1776 28.1772C39.3957 27.7737 40.5513 27.2011 41.6109 26.476C42.2002 26.0621 42.7272 25.5654 43.1754 25.0011C43.6722 24.3811 44.0271 23.6594 44.2152 22.8869L40.2915 21.9528C40.2471 22.1186 40.1704 22.274 40.0659 22.41C39.923 22.5933 39.7583 22.7584 39.5754 22.9017C39.1249 23.2614 38.6304 23.562 38.104 23.7965C37.2858 24.1735 36.4356 24.4763 35.5634 24.7011C35.2005 24.7946 34.8915 24.8634 34.6806 24.9076L34.4452 24.9568H34.3912L34.6021 26.2155L34.3912 24.9568L34.6021 26.2155L34.3912 24.9568L35.0435 29.0081Z" fill="black"/>
            <path id={"fill_color"} d="M16.102 16.1708L15.6115 13.9338C15.1567 11.8311 15.5496 9.63356 16.7045 7.82012C17.8594 6.00668 19.6826 4.72443 21.7766 4.25293C22.9531 3.99153 24.173 3.9976 25.3468 4.27071C26.5206 4.54382 27.6184 5.07702 28.5597 5.83117C30.0199 6.98656 31.037 8.61229 31.4387 10.4331L31.7281 11.7508L35.6861 10.8707L35.3968 9.55797C34.7013 6.40184 32.7837 3.65188 30.0657 1.91294C27.3478 0.174008 24.0521 -0.411492 20.9036 0.285224C18.1752 0.888026 15.7388 2.41841 14.0077 4.61676C12.8821 6.03229 12.0862 7.68198 11.678 9.44533C11.2699 11.2087 11.2598 13.0412 11.6485 14.8089L12.139 17.0509L16.097 16.1708H16.102Z" fill="black"/>
            <path id={"fill_color"} d="M57.497 55.4496C55.7764 58.3608 52.9851 60.4792 49.7232 61.3496L26.1515 67.7412C24.1724 68.2824 22.0933 68.344 20.0858 67.9209C18.0783 67.4977 16.2 66.6019 14.606 65.3074C12.2466 63.4082 10.6271 60.739 10.03 57.7653L7.1951 58.3455L10.0545 57.9178L5.88556 30.0307C5.82156 29.5894 5.78878 29.1442 5.78747 28.6983C5.79132 26.7384 6.43764 24.8342 7.62694 23.2786C8.81624 21.723 10.4826 20.6024 12.3695 20.0893L39.5215 12.739C41.5084 12.2025 43.6242 12.4397 45.4438 13.4028C47.2635 14.3659 48.6521 15.9836 49.3308 17.9309L57.5264 41.4373L62.9902 39.5247L54.8044 16.0183C53.6452 12.683 51.2678 9.91226 48.1514 8.2645C45.035 6.61674 41.4114 6.21453 38.0109 7.13893L10.8638 14.5139C7.75322 15.3592 5.00559 17.2049 3.04295 19.7675C1.08032 22.33 0.0112583 25.4676 0 28.6983C0.000768665 29.4322 0.0548627 30.1651 0.161853 30.8911L4.3406 58.8519V58.9257C4.84922 61.4499 5.8697 63.8424 7.33876 65.955C8.80783 68.0675 10.6942 69.855 12.8808 71.2066C15.0674 72.5582 17.5077 73.4451 20.0504 73.8123C22.593 74.1795 25.1838 74.0191 27.6621 73.3412L51.2289 66.9496C55.9452 65.6816 59.9797 62.6148 62.4703 58.4045L57.497 55.4545V55.4496Z" fill="black"/> 
      </svg>
  );
};

export const LogoWrapIcon = () => {
    return (
      <svg width="63" height="74" viewBox="0 0 63 74" fill="none" xmlns="http://www.w3.org/2000/svg">
     
            <path id={"fill_color"} d="M37.5695 25.4435C37.6284 25.5664 37.6774 25.6795 37.7216 25.7877L38.7025 28.246C38.9954 28.9756 39.1437 29.7555 39.139 30.542C39.1356 31.36 38.993 32.1715 38.7172 32.9413C38.2486 34.2366 37.4999 35.4119 36.5248 36.383C34.3758 38.5217 31.4751 39.7293 28.4469 39.7459C26.8071 39.7646 25.1914 39.349 23.763 38.5414C23.1318 38.1763 22.561 37.7152 22.0709 37.1745C21.61 36.6501 21.2432 36.0496 20.987 35.3996L20.006 32.9413C19.9668 32.8381 19.9227 32.7201 19.8785 32.5923L16.0431 33.9001C16.1019 34.0722 16.1706 34.259 16.2491 34.4557L17.23 36.914C17.6716 37.9948 18.2936 38.9924 19.0692 39.8639C20.2243 41.1573 21.6608 42.1666 23.2676 42.8139C24.9135 43.4783 26.6726 43.8156 28.4469 43.8071C30.4175 43.8025 32.3689 43.4186 34.1951 42.6762C36.7803 41.655 39.0342 39.9384 40.7085 37.7154C41.487 36.6748 42.1054 35.5229 42.5428 34.2983C42.9736 33.0946 43.1958 31.8258 43.2 30.5469C43.2062 29.2417 42.9597 27.9477 42.4742 26.7366L41.4932 24.2783C41.4148 24.0865 41.3363 23.9046 41.2578 23.7374L37.5695 25.4435Z" fill="black"/>
            <path id={"fill_color"} d="M13.1493 36.147H13.3799C13.6937 36.147 14.3068 36.147 15.0818 36.0733C15.6654 36.0241 16.3423 35.9454 17.0436 35.8127C18.2137 35.6109 19.3496 35.2452 20.418 34.7261C21.0173 34.4378 21.5737 34.0671 22.0709 33.6248C22.6014 33.1386 23.0285 32.5499 23.3264 31.8942L19.6185 30.2274C19.5533 30.364 19.4635 30.4873 19.3537 30.5912C19.0038 30.9094 18.5934 31.1536 18.1472 31.3091C17.2551 31.6437 16.3237 31.8618 15.376 31.9581C14.7136 32.0391 14.0471 32.0818 13.3799 32.0859H13.2425L13.1395 36.1421L13.1493 36.147Z" fill="black"/>
            <path id={"fill_color"} d="M35.0435 29.0081C35.0828 29.0081 35.534 28.9245 36.255 28.7475C36.7896 28.6148 37.4517 28.4329 38.1776 28.1772C39.3957 27.7737 40.5513 27.2011 41.6109 26.476C42.2002 26.0621 42.7272 25.5654 43.1754 25.0011C43.6722 24.3811 44.0271 23.6594 44.2152 22.8869L40.2915 21.9528C40.2471 22.1186 40.1704 22.274 40.0659 22.41C39.923 22.5933 39.7583 22.7584 39.5754 22.9017C39.1249 23.2614 38.6304 23.562 38.104 23.7965C37.2858 24.1735 36.4356 24.4763 35.5634 24.7011C35.2005 24.7946 34.8915 24.8634 34.6806 24.9076L34.4452 24.9568H34.3912L34.6021 26.2155L34.3912 24.9568L34.6021 26.2155L34.3912 24.9568L35.0435 29.0081Z" fill="black"/>
            <path id={"fill_color"} d="M16.102 16.1708L15.6115 13.9338C15.1567 11.8311 15.5496 9.63356 16.7045 7.82012C17.8594 6.00668 19.6826 4.72443 21.7766 4.25293C22.9531 3.99153 24.173 3.9976 25.3468 4.27071C26.5206 4.54382 27.6184 5.07702 28.5597 5.83117C30.0199 6.98656 31.037 8.61229 31.4387 10.4331L31.7281 11.7508L35.6861 10.8707L35.3968 9.55797C34.7013 6.40184 32.7837 3.65188 30.0657 1.91294C27.3478 0.174008 24.0521 -0.411492 20.9036 0.285224C18.1752 0.888026 15.7388 2.41841 14.0077 4.61676C12.8821 6.03229 12.0862 7.68198 11.678 9.44533C11.2699 11.2087 11.2598 13.0412 11.6485 14.8089L12.139 17.0509L16.097 16.1708H16.102Z" fill="black"/>
            <path d="M57.497 55.4496C55.7764 58.3608 52.9851 60.4792 49.7232 61.3496L26.1515 67.7412C24.1724 68.2824 22.0933 68.344 20.0858 67.9209C18.0783 67.4977 16.2 66.6019 14.606 65.3074C12.2466 63.4082 10.6271 60.739 10.03 57.7653L7.1951 58.3455L10.0545 57.9178L5.88556 30.0307C5.82156 29.5894 5.78878 29.1442 5.78747 28.6983C5.79132 26.7384 6.43764 24.8342 7.62694 23.2786C8.81624 21.723 10.4826 20.6024 12.3695 20.0893L39.5215 12.739C41.5084 12.2025 43.6242 12.4397 45.4438 13.4028C47.2635 14.3659 48.6521 15.9836 49.3308 17.9309L57.5264 41.4373L62.9902 39.5247L54.8044 16.0183C53.6452 12.683 51.2678 9.91226 48.1514 8.2645C45.035 6.61674 41.4114 6.21453 38.0109 7.13893L10.8638 14.5139C7.75322 15.3592 5.00559 17.2049 3.04295 19.7675C1.08032 22.33 0.0112583 25.4676 0 28.6983C0.000768665 29.4322 0.0548627 30.1651 0.161853 30.8911L4.3406 58.8519V58.9257C4.84922 61.4499 5.8697 63.8424 7.33876 65.955C8.80783 68.0675 10.6942 69.855 12.8808 71.2066C15.0674 72.5582 17.5077 73.4451 20.0504 73.8123C22.593 74.1795 25.1838 74.0191 27.6621 73.3412L51.2289 66.9496C55.9452 65.6816 59.9797 62.6148 62.4703 58.4045L57.497 55.4545V55.4496Z" fill="#FF971F"/>
      </svg>
    );
};

export const Illustration1 = () => {
  return (
    <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M131.863 180.285C157.822 177.125 176.304 153.52 173.144 127.561C169.985 101.602 146.379 83.1191 120.42 86.2789C94.4609 89.4386 75.9784 113.044 79.1382 139.003C82.2979 164.962 105.903 183.445 131.863 180.285Z" fill="#EBEBEB"/>
      <path d="M151.707 158.848C165.824 144.73 165.824 121.841 151.707 107.724C137.589 93.6064 114.7 93.6064 100.583 107.724C86.4653 121.841 86.4653 144.73 100.583 158.848C114.7 172.965 137.589 172.965 151.707 158.848Z" fill="white"/>
      <path d="M126.14 159.12C140.406 159.12 151.97 147.556 151.97 133.29C151.97 119.024 140.406 107.46 126.14 107.46C111.875 107.46 100.31 119.024 100.31 133.29C100.31 147.556 111.875 159.12 126.14 159.12Z" fill="#EBEBEB"/>
      <path d="M129.455 147.172C137.128 145.348 141.87 137.65 140.046 129.977C138.222 122.304 130.524 117.562 122.851 119.386C115.178 121.21 110.436 128.908 112.26 136.581C114.084 144.254 121.782 148.996 129.455 147.172Z" fill="white"/>
      <path d="M119.69 133.29C119.692 134.565 120.072 135.811 120.782 136.871C121.492 137.93 122.5 138.755 123.679 139.242C124.857 139.729 126.154 139.855 127.404 139.605C128.655 139.355 129.803 138.74 130.704 137.837C131.605 136.935 132.219 135.786 132.467 134.535C132.715 133.284 132.586 131.987 132.098 130.809C131.61 129.631 130.783 128.625 129.722 127.916C128.662 127.208 127.415 126.83 126.14 126.83C125.292 126.83 124.453 126.997 123.669 127.322C122.886 127.647 122.175 128.123 121.576 128.723C120.977 129.323 120.502 130.035 120.178 130.819C119.854 131.602 119.689 132.442 119.69 133.29Z" fill="#EBEBEB"/>
      <path d="M61.4748 67.1439L60.004 68.6147L121.197 129.808L122.668 128.337L61.4748 67.1439Z" fill="#A6A6A6"/>
      <path d="M118.14 131.14L126.14 133.29L124 125.29L118.14 131.14Z" fill="#A6A6A6"/>
      <path d="M50.5 63.9L61.97 75.37L70.97 78.12L68.22 69.12L56.75 57.64L59.5 66.64L50.5 63.9Z" fill="#A6A6A6"/>
      <path d="M338.07 55.39L341.39 60.34L347.13 61.97L343.45 66.65L343.67 72.61L338.07 70.56L332.48 72.61L332.7 66.65L329.02 61.97L334.75 60.34L338.07 55.39Z" fill="#EBEBEB"/>
      <path d="M363.67 55.39L366.99 60.34L372.72 61.97L369.04 66.65L369.26 72.61L363.67 70.56L358.08 72.61L358.3 66.65L354.62 61.97L360.35 60.34L363.67 55.39Z" fill="#EBEBEB"/>
      <path d="M389.26 55.39L392.58 60.34L398.31 61.97L394.63 66.65L394.86 72.61L389.26 70.56L383.67 72.61L383.89 66.65L380.21 61.97L385.94 60.34L389.26 55.39Z" fill="#EBEBEB"/>
      <path d="M414.86 55.39L418.18 60.34L423.91 61.97L420.23 66.65L420.45 72.61L414.86 70.56L409.26 72.61L409.49 66.65L405.81 61.97L411.54 60.34L414.86 55.39Z" fill="#EBEBEB"/>
      <path d="M434.33 73.33L434.58 66.82L430.58 61.69L436.85 59.91L440.48 54.49L444.12 59.91L450.39 61.69L446.39 66.82L446.63 73.33L440.51 71.09L434.33 73.33ZM440.45 70L445.52 71.86L445.32 66.47L448.65 62.22L443.46 60.75L440.46 56.27L437.46 60.75L432.27 62.22L435.6 66.47L435.4 71.86L440.45 70Z" fill="#EBEBEB"/>
      <path d="M440.45 70.56V55.39L437.13 60.34L431.4 61.97L435.08 66.65L434.86 72.61L440.45 70.56Z" fill="#EBEBEB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M408.49 264.39C407.22 263.6 406.11 262.45 404.42 262.62C403.77 262.69 403.91 262.15 403.91 261.79C403.91 255.417 403.883 249.05 403.83 242.69C403.83 242.23 403.83 241.84 404.36 241.59C409.22 239.12 411.31 234.29 414.28 230.15C415.798 228.095 416.889 225.757 417.487 223.273C418.085 220.789 418.177 218.21 417.76 215.69C417.55 214.26 418.21 213.61 419.5 213.32C421.06 212.96 422.61 213.38 423.14 214.45C424.55 217.044 425.501 219.862 425.95 222.78C426.24 225.2 425.38 227.55 425.09 229.94C424.87 231.69 424.47 233.42 424.14 235.19C424.57 235.5 425.06 235.34 425.52 235.35H441.66C443.93 235.35 445.04 235.99 445.36 237.49C445.536 238.415 445.405 239.372 444.986 240.216C444.567 241.059 443.884 241.742 443.04 242.16C442.63 242.37 441.91 242.16 441.86 242.82C441.81 243.48 442.56 243.39 442.93 243.65C444.93 244.98 444.53 247.73 442.29 249.34C441.74 249.72 440.69 249.54 440.67 250.34C440.67 250.97 441.67 250.96 442.09 251.41C443.67 252.96 442.9 255.86 440.55 257.04C440.228 257.141 439.914 257.264 439.61 257.41C439.34 257.59 439.08 257.79 439.61 258.16C441.94 259.81 441.9 262.16 439.56 263.83L438.86 264.33C438.012 264.847 437.017 265.069 436.03 264.96C427.59 265.007 419.153 265.037 410.72 265.05C410.323 265.094 409.921 265.058 409.538 264.945C409.155 264.832 408.799 264.643 408.49 264.39Z" fill="#EBEBEB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M384.46 253.24C384.46 249.24 384.46 245.15 384.4 241.1C384.4 240.28 384.48 239.87 385.49 239.88C390.43 239.933 395.363 239.933 400.29 239.88C401.29 239.88 401.41 240.2 401.41 241.06C401.41 249.2 401.437 257.34 401.49 265.48C401.49 266.48 401.22 266.81 400.18 266.8C395.4 266.8 390.61 266.8 385.82 266.86C384.63 266.86 384.47 266.46 384.48 265.42C384.52 261.33 384.47 257.29 384.46 253.24Z" fill="#EBEBEB"/>
      <path d="M197.71 446.94H66.7101C66.0152 446.94 65.3488 446.664 64.8575 446.173C64.3661 445.681 64.0901 445.015 64.0901 444.32V428.71C64.0901 428.66 64.0901 428.61 64.0901 428.56C64.0901 428.51 64.0901 428.38 64.0901 428.28L80.0001 370.13L59.4901 243L52.6401 240.62C52.041 240.374 51.5544 239.914 51.2742 239.33C50.994 238.746 50.94 238.079 51.1227 237.458C51.3054 236.836 51.7118 236.305 52.2635 235.965C52.8152 235.626 53.473 235.503 54.1101 235.62L62.5601 238.47C63.0387 238.61 63.4674 238.884 63.7952 239.259C64.1231 239.635 64.3364 240.097 64.4101 240.59L85.2501 369.79C85.3052 370.081 85.3052 370.379 85.2501 370.67L69.8701 426.39L191.87 435.06C192.547 435.107 193.18 435.411 193.64 435.91L199.64 442.57C199.978 442.946 200.199 443.412 200.278 443.911C200.357 444.41 200.29 444.921 200.085 445.383C199.88 445.845 199.546 446.238 199.122 446.514C198.699 446.79 198.205 446.938 197.7 446.94H197.71ZM69.3601 441.71H191.81L190.46 440.21L69.3601 431.6V441.71Z" fill="#C7C7C7"/>
      <path d="M245.66 284.31H69.12C68.763 284.332 68.4053 284.281 68.0688 284.159C67.7324 284.038 67.4244 283.849 67.1637 283.604C66.9031 283.359 66.6954 283.063 66.5535 282.735C66.4115 282.407 66.3383 282.053 66.3383 281.695C66.3383 281.337 66.4115 280.983 66.5535 280.655C66.6954 280.327 66.9031 280.031 67.1637 279.786C67.4244 279.541 67.7324 279.352 68.0688 279.231C68.4053 279.109 68.763 279.058 69.12 279.08H245.66C246.017 279.058 246.375 279.109 246.711 279.231C247.048 279.352 247.356 279.541 247.616 279.786C247.877 280.031 248.085 280.327 248.227 280.655C248.369 280.983 248.442 281.337 248.442 281.695C248.442 282.053 248.369 282.407 248.227 282.735C248.085 283.063 247.877 283.359 247.616 283.604C247.356 283.849 247.048 284.038 246.711 284.159C246.375 284.281 246.017 284.332 245.66 284.31Z" fill="#C7C7C7"/>
      <path d="M82.66 371.06C82.4441 371.037 82.2443 370.935 82.0991 370.773C81.954 370.612 81.8737 370.402 81.8737 370.185C81.8737 369.968 81.954 369.758 82.0991 369.597C82.2443 369.435 82.4441 369.333 82.66 369.31L215.28 362.22L241.39 281.43C241.425 281.321 241.482 281.22 241.556 281.132C241.63 281.045 241.721 280.973 241.823 280.921C241.926 280.869 242.037 280.838 242.152 280.829C242.266 280.82 242.381 280.834 242.49 280.87C242.599 280.909 242.699 280.969 242.784 281.048C242.869 281.126 242.938 281.221 242.986 281.326C243.033 281.432 243.059 281.546 243.061 281.662C243.064 281.778 243.043 281.893 243 282L216.7 363.36C216.647 363.529 216.545 363.678 216.405 363.787C216.266 363.896 216.097 363.96 215.92 363.97L82.71 371.05L82.66 371.06Z" fill="#C7C7C7"/>
      <path d="M218.16 356.64H80.24C80.0093 356.64 79.788 356.548 79.6248 356.385C79.4617 356.222 79.37 356.001 79.37 355.77C79.37 355.539 79.4617 355.318 79.6248 355.155C79.788 354.992 80.0093 354.9 80.24 354.9H218.16C218.391 354.9 218.612 354.992 218.775 355.155C218.938 355.318 219.03 355.539 219.03 355.77C219.03 356.001 218.938 356.222 218.775 356.385C218.612 356.548 218.391 356.64 218.16 356.64Z" fill="#C7C7C7"/>
      <path d="M221.67 346H78.61C78.3793 346 78.158 345.908 77.9948 345.745C77.8317 345.582 77.74 345.361 77.74 345.13C77.74 344.899 77.8317 344.678 77.9948 344.515C78.158 344.352 78.3793 344.26 78.61 344.26H221.67C221.901 344.26 222.122 344.352 222.285 344.515C222.448 344.678 222.54 344.899 222.54 345.13C222.54 345.361 222.448 345.582 222.285 345.745C222.122 345.908 221.901 346 221.67 346Z" fill="#C7C7C7"/>
      <path d="M224.91 335.43H77.0001C76.7841 335.407 76.5844 335.305 76.4392 335.143C76.294 334.982 76.2137 334.772 76.2137 334.555C76.2137 334.338 76.294 334.128 76.4392 333.967C76.5844 333.805 76.7841 333.703 77.0001 333.68H224.91C225.033 333.667 225.157 333.68 225.274 333.718C225.392 333.756 225.5 333.818 225.592 333.9C225.684 333.983 225.757 334.084 225.807 334.197C225.858 334.309 225.884 334.432 225.884 334.555C225.884 334.678 225.858 334.801 225.807 334.913C225.757 335.026 225.684 335.127 225.592 335.21C225.5 335.292 225.392 335.354 225.274 335.392C225.157 335.43 225.033 335.443 224.91 335.43Z" fill="#C7C7C7"/>
      <path d="M228.43 324.82H75.37C75.2473 324.833 75.1231 324.82 75.0056 324.782C74.8882 324.744 74.78 324.682 74.6882 324.6C74.5963 324.517 74.5229 324.416 74.4726 324.303C74.4223 324.191 74.3964 324.068 74.3964 323.945C74.3964 323.822 74.4223 323.699 74.4726 323.587C74.5229 323.474 74.5963 323.373 74.6882 323.29C74.78 323.208 74.8882 323.146 75.0056 323.108C75.1231 323.07 75.2473 323.057 75.37 323.07H228.43C228.646 323.093 228.846 323.195 228.991 323.357C229.136 323.518 229.216 323.728 229.216 323.945C229.216 324.162 229.136 324.372 228.991 324.533C228.846 324.695 228.646 324.797 228.43 324.82Z" fill="#C7C7C7"/>
      <path d="M231.78 314.21H73.78C73.5493 314.21 73.328 314.118 73.1648 313.955C73.0017 313.792 72.91 313.571 72.91 313.34C72.91 313.109 73.0017 312.888 73.1648 312.725C73.328 312.562 73.5493 312.47 73.78 312.47H231.78C232.011 312.47 232.232 312.562 232.395 312.725C232.558 312.888 232.65 313.109 232.65 313.34C232.65 313.571 232.558 313.792 232.395 313.955C232.232 314.118 232.011 314.21 231.78 314.21Z" fill="#C7C7C7"/>
      <path d="M235.38 303.6H72.12C71.8893 303.6 71.668 303.508 71.5048 303.345C71.3417 303.182 71.25 302.961 71.25 302.73C71.25 302.499 71.3417 302.278 71.5048 302.115C71.668 301.952 71.8893 301.86 72.12 301.86H235.38C235.611 301.86 235.832 301.952 235.995 302.115C236.158 302.278 236.25 302.499 236.25 302.73C236.25 302.961 236.158 303.182 235.995 303.345C235.832 303.508 235.611 303.6 235.38 303.6Z" fill="#C7C7C7"/>
      <path d="M238.81 293H70.5C70.2693 293 70.048 292.908 69.8848 292.745C69.7217 292.582 69.63 292.361 69.63 292.13C69.63 291.899 69.7217 291.678 69.8848 291.515C70.048 291.352 70.2693 291.26 70.5 291.26H238.81C239.041 291.26 239.262 291.352 239.425 291.515C239.588 291.678 239.68 291.899 239.68 292.13C239.68 292.361 239.588 292.582 239.425 292.745C239.262 292.908 239.041 293 238.81 293Z" fill="#C7C7C7"/>
      <path d="M94.57 370C94.3492 370.001 94.1366 369.917 93.9751 369.767C93.8135 369.616 93.7152 369.41 93.7 369.19L87 281.76C86.9904 281.646 87.0036 281.532 87.0388 281.423C87.074 281.314 87.1305 281.214 87.205 281.127C87.2794 281.041 87.3704 280.97 87.4726 280.919C87.5747 280.868 87.686 280.838 87.8 280.83C88.0297 280.816 88.2559 280.891 88.4312 281.041C88.6064 281.19 88.7171 281.401 88.74 281.63L95.44 369C95.4565 369.229 95.3817 369.456 95.2318 369.63C95.0819 369.804 94.8691 369.912 94.64 369.93L94.57 370Z" fill="#C7C7C7"/>
      <path d="M202.7 364.25C202.627 364.26 202.553 364.26 202.48 364.25C202.257 364.189 202.066 364.043 201.95 363.842C201.834 363.642 201.802 363.404 201.86 363.18L224.07 281.5C224.131 281.277 224.277 281.086 224.478 280.97C224.678 280.854 224.916 280.822 225.14 280.88C225.25 280.91 225.354 280.962 225.444 281.032C225.535 281.102 225.61 281.189 225.667 281.289C225.724 281.388 225.76 281.497 225.774 281.611C225.789 281.725 225.78 281.84 225.75 281.95L203.55 363.63C203.493 363.81 203.379 363.968 203.226 364.079C203.074 364.191 202.889 364.25 202.7 364.25Z" fill="#C7C7C7"/>
      <path d="M108.5 369.3C108.272 369.297 108.053 369.206 107.891 369.046C107.729 368.885 107.635 368.668 107.63 368.44L105.47 281.72C105.467 281.489 105.554 281.266 105.713 281.098C105.872 280.93 106.089 280.83 106.32 280.82C106.548 280.82 106.768 280.908 106.932 281.067C107.096 281.226 107.192 281.442 107.2 281.67L109.35 368.4C109.355 368.632 109.269 368.856 109.11 369.025C108.951 369.193 108.732 369.292 108.5 369.3Z" fill="#C7C7C7"/>
      <path d="M122.8 368.52C122.686 368.517 122.573 368.492 122.469 368.446C122.364 368.4 122.27 368.334 122.191 368.251C122.112 368.168 122.05 368.071 122.009 367.964C121.967 367.858 121.947 367.744 121.95 367.63L123.55 281.68C123.555 281.452 123.649 281.235 123.811 281.074C123.974 280.914 124.192 280.823 124.42 280.82C124.534 280.823 124.647 280.848 124.752 280.894C124.856 280.94 124.951 281.006 125.029 281.089C125.108 281.172 125.17 281.269 125.212 281.376C125.253 281.482 125.273 281.596 125.27 281.71L123.67 367.71C123.655 367.93 123.557 368.136 123.395 368.287C123.233 368.437 123.021 368.521 122.8 368.52Z" fill="#C7C7C7"/>
      <path d="M187.48 364.93H187.31C187.088 364.886 186.892 364.757 186.765 364.57C186.637 364.383 186.589 364.153 186.63 363.93L203.5 281.57C203.55 281.352 203.681 281.16 203.867 281.034C204.052 280.908 204.278 280.857 204.5 280.89C204.721 280.936 204.915 281.066 205.042 281.253C205.169 281.439 205.218 281.668 205.18 281.89L188.31 364.25C188.266 364.439 188.161 364.608 188.011 364.731C187.861 364.854 187.674 364.924 187.48 364.93Z" fill="#C7C7C7"/>
      <path d="M170.14 366H170C169.773 365.961 169.57 365.834 169.436 365.647C169.301 365.46 169.245 365.228 169.28 365L182.94 281.6C182.979 281.374 183.106 281.172 183.294 281.039C183.481 280.906 183.713 280.852 183.94 280.89C184.053 280.908 184.161 280.949 184.258 281.009C184.355 281.069 184.438 281.148 184.505 281.241C184.571 281.334 184.618 281.44 184.643 281.551C184.667 281.662 184.67 281.778 184.65 281.89L171 365.24C170.971 365.449 170.869 365.64 170.711 365.779C170.553 365.919 170.351 365.997 170.14 366Z" fill="#C7C7C7"/>
      <path d="M137.44 367.69H137.37C137.256 367.682 137.145 367.652 137.043 367.601C136.94 367.55 136.85 367.479 136.775 367.393C136.701 367.306 136.644 367.206 136.609 367.097C136.574 366.988 136.56 366.874 136.57 366.76L142.94 281.63C142.957 281.399 143.065 281.185 143.241 281.034C143.416 280.883 143.644 280.808 143.875 280.825C144.106 280.842 144.32 280.95 144.471 281.126C144.622 281.301 144.697 281.529 144.68 281.76L138.31 366.89C138.29 367.108 138.19 367.31 138.029 367.458C137.869 367.606 137.658 367.688 137.44 367.69Z" fill="#C7C7C7"/>
      <path d="M154.59 366.58H154.49C154.373 366.567 154.26 366.53 154.158 366.472C154.055 366.414 153.965 366.336 153.894 366.243C153.822 366.149 153.77 366.043 153.74 365.929C153.71 365.815 153.703 365.696 153.72 365.58L163.06 281.58C163.072 281.466 163.107 281.356 163.162 281.256C163.217 281.156 163.291 281.068 163.381 280.996C163.47 280.925 163.572 280.871 163.682 280.839C163.791 280.808 163.906 280.798 164.02 280.81C164.134 280.822 164.244 280.857 164.344 280.912C164.444 280.967 164.532 281.041 164.604 281.131C164.675 281.22 164.729 281.322 164.76 281.432C164.792 281.541 164.802 281.656 164.79 281.77L155.45 365.77C155.435 365.988 155.338 366.193 155.179 366.343C155.019 366.494 154.809 366.578 154.59 366.58Z" fill="#C7C7C7"/>
      <path d="M83.7101 460.56C89.1059 460.56 93.4801 456.186 93.4801 450.79C93.4801 445.394 89.1059 441.02 83.7101 441.02C78.3142 441.02 73.9401 445.394 73.9401 450.79C73.9401 456.186 78.3142 460.56 83.7101 460.56Z" fill="#263238"/>
      <path d="M88.15 450.79C88.15 451.668 87.8896 452.527 87.4017 453.257C86.9139 453.987 86.2204 454.556 85.4091 454.892C84.5978 455.228 83.7051 455.316 82.8438 455.145C81.9825 454.973 81.1914 454.551 80.5705 453.93C79.9495 453.309 79.5267 452.517 79.3553 451.656C79.184 450.795 79.2719 449.902 79.608 449.091C79.944 448.28 80.5131 447.586 81.2433 447.098C81.9734 446.61 82.8319 446.35 83.71 446.35C84.8876 446.35 86.0169 446.818 86.8496 447.65C87.6822 448.483 88.15 449.612 88.15 450.79Z" fill="#EBEBEB"/>
      <path d="M171.14 460.56C176.536 460.56 180.91 456.186 180.91 450.79C180.91 445.394 176.536 441.02 171.14 441.02C165.744 441.02 161.37 445.394 161.37 450.79C161.37 456.186 165.744 460.56 171.14 460.56Z" fill="#263238"/>
      <path d="M175.58 450.79C175.58 451.668 175.32 452.527 174.832 453.257C174.344 453.987 173.65 454.556 172.839 454.892C172.028 455.228 171.135 455.316 170.274 455.145C169.412 454.973 168.621 454.551 168 453.93C167.379 453.309 166.957 452.517 166.785 451.656C166.614 450.795 166.702 449.902 167.038 449.091C167.374 448.28 167.943 447.586 168.673 447.098C169.403 446.61 170.262 446.35 171.14 446.35C172.318 446.35 173.447 446.818 174.28 447.65C175.112 448.483 175.58 449.612 175.58 450.79Z" fill="#EBEBEB"/>
      <path d="M59.89 243.53C59.5281 243.531 59.169 243.467 58.83 243.34L45.07 238.12C44.7017 237.98 44.3645 237.769 44.0777 237.499C43.7909 237.229 43.5601 236.905 43.3985 236.545C43.2369 236.186 43.1477 235.798 43.136 235.405C43.1242 235.011 43.1902 234.618 43.33 234.25C43.4699 233.882 43.6809 233.544 43.9511 233.258C44.2213 232.971 44.5453 232.74 44.9047 232.578C45.264 232.417 45.6517 232.328 46.0455 232.316C46.4394 232.304 46.8317 232.37 47.2 232.51L61 237.73C61.6534 237.981 62.1983 238.453 62.5404 239.064C62.8825 239.675 63.0002 240.386 62.8732 241.074C62.7462 241.763 62.3825 242.385 61.845 242.834C61.3075 243.282 60.63 243.528 59.93 243.53H59.89Z" fill="#FB6524"/>
      <path d="M48.58 460.56C177.58 459.95 322.38 459.94 451.42 460.56C322.42 461.18 177.62 461.17 48.58 460.56Z" fill="#263238"/>
      <path d="M334.52 77.71H165.46C163.193 77.7309 160.953 78.1981 158.867 79.0849C156.781 79.9716 154.89 81.2606 153.302 82.8783C151.714 84.4959 150.46 86.4104 149.612 88.5126C148.764 90.6147 148.339 92.8633 148.36 95.13V443.13C148.32 447.707 150.099 452.112 153.305 455.379C156.511 458.645 160.883 460.505 165.46 460.55H334.52C339.099 460.505 343.473 458.646 346.682 455.38C349.892 452.114 351.675 447.709 351.64 443.13V95.13C351.677 90.5504 349.895 86.1434 346.685 82.877C343.475 79.6107 339.099 77.7522 334.52 77.71ZM340.94 432.38C340.94 441.54 334.08 448.95 325.62 448.95H174.38C165.9 448.95 159.04 441.54 159.04 432.38V108.32C159.04 89.66 165.9 88.75 174.38 88.75H204.68C206.44 88.75 207.88 90.75 207.88 93.23V94.3C207.88 100.11 211.06 100.13 214.96 100.13H285C288.94 100.13 292.1 100.13 292.1 94.3V93.23C292.1 90.75 293.52 88.75 295.31 88.75H325.6C334.06 88.75 340.92 91.23 340.92 108.32L340.94 432.38Z" fill="#263238"/>
      <path d="M341 108.61V432.67C341 441.83 334.14 449.24 325.68 449.24H174.39C165.91 449.24 159.05 441.83 159.05 432.67V108.61C159.05 90 165.91 89 174.39 89H204.69C206.45 89 207.89 91 207.89 93.48V94.55C207.89 100.36 211.07 100.38 214.97 100.38H285C288.94 100.38 292.1 100.38 292.1 94.55V93.52C292.1 91.04 293.52 89.04 295.31 89.04H325.6C334.09 89 341 91.52 341 108.61Z" fill="white"/>
      <path d="M259.55 92.87H240.44C240.127 92.87 239.827 92.7457 239.606 92.5244C239.384 92.3031 239.26 92.0029 239.26 91.69C239.26 91.377 239.384 91.0769 239.606 90.8556C239.827 90.6343 240.127 90.51 240.44 90.51H259.55C259.863 90.51 260.163 90.6343 260.384 90.8556C260.606 91.0769 260.73 91.377 260.73 91.69C260.73 92.0029 260.606 92.3031 260.384 92.5244C260.163 92.7457 259.863 92.87 259.55 92.87Z" fill="white"/>
      <path d="M199.45 96L199.16 96.29L198.99 96.46C198.24 95.715 197.227 95.2968 196.17 95.2968C195.113 95.2968 194.099 95.715 193.35 96.46C193.292 96.4083 193.238 96.3514 193.19 96.29C193.09 96.2 193 96.1 192.9 96C193.03 95.9 193.15 95.78 193.27 95.68C193.532 95.4612 193.821 95.2764 194.13 95.13C194.486 94.95 194.867 94.822 195.26 94.75L195.83 94.68H196.33H196.71C197.052 94.7166 197.388 94.7973 197.71 94.92C197.956 95.0095 198.193 95.1199 198.42 95.25C198.655 95.3893 198.879 95.5464 199.09 95.72L199.4 96H199.45Z" fill="#A6A6A6"/>
      <path d="M194.27 97.41C194.12 97.25 193.97 97.1 193.81 96.95C194.118 96.6395 194.485 96.393 194.889 96.2249C195.294 96.0567 195.727 95.9702 196.165 95.9702C196.603 95.9702 197.036 96.0567 197.44 96.2249C197.844 96.393 198.211 96.6395 198.52 96.95L198.06 97.41C197.557 96.9074 196.876 96.6251 196.165 96.6251C195.454 96.6251 194.772 96.9074 194.27 97.41Z" fill="#A6A6A6"/>
      <path d="M197.6 97.87L197.14 98.33C196.882 98.0747 196.533 97.9316 196.17 97.9316C195.807 97.9316 195.458 98.0747 195.2 98.33C195.04 98.18 194.89 98.02 194.74 97.87C194.926 97.6795 195.149 97.5281 195.394 97.4248C195.64 97.3215 195.904 97.2682 196.17 97.2682C196.436 97.2682 196.7 97.3215 196.946 97.4248C197.191 97.5281 197.414 97.6795 197.6 97.87Z" fill="#A6A6A6"/>
      <path d="M196.58 98.75C196.58 98.8587 196.537 98.963 196.46 99.0399C196.383 99.1168 196.279 99.16 196.17 99.16C196.115 99.1613 196.061 99.1517 196.01 99.1317C195.959 99.1117 195.913 99.0817 195.874 99.0435C195.834 99.0053 195.803 98.9596 195.782 98.9092C195.761 98.8588 195.75 98.8047 195.75 98.75C195.75 98.6386 195.794 98.5318 195.873 98.453C195.952 98.3742 196.059 98.33 196.17 98.33C196.225 98.33 196.279 98.3409 196.329 98.3621C196.38 98.3833 196.425 98.4144 196.464 98.4536C196.502 98.4927 196.532 98.5391 196.552 98.59C196.572 98.6409 196.581 98.6953 196.58 98.75Z" fill="#A6A6A6"/>
      <path d="M170.9 96.91C170.9 96.6707 170.971 96.4367 171.104 96.2377C171.237 96.0388 171.426 95.8837 171.647 95.7921C171.868 95.7005 172.111 95.6765 172.346 95.7232C172.581 95.7699 172.796 95.8852 172.966 96.0544C173.135 96.2236 173.25 96.4392 173.297 96.6739C173.343 96.9086 173.319 97.1519 173.228 97.373C173.136 97.5941 172.981 97.7831 172.782 97.9161C172.583 98.049 172.349 98.12 172.11 98.12C171.789 98.12 171.481 97.9925 171.254 97.7656C171.027 97.5387 170.9 97.2309 170.9 96.91Z" fill="#A6A6A6"/>
      <path d="M174.7 96.91C174.7 96.6707 174.771 96.4367 174.904 96.2377C175.037 96.0388 175.226 95.8837 175.447 95.7921C175.668 95.7005 175.911 95.6765 176.146 95.7232C176.381 95.7699 176.596 95.8852 176.766 96.0544C176.935 96.2236 177.05 96.4392 177.097 96.6739C177.143 96.9086 177.119 97.1519 177.028 97.373C176.936 97.5941 176.781 97.7831 176.582 97.9161C176.383 98.049 176.149 98.12 175.91 98.12C175.751 98.12 175.594 98.0887 175.447 98.0279C175.3 97.9671 175.167 97.8779 175.054 97.7656C174.942 97.6532 174.853 97.5198 174.792 97.373C174.731 97.2262 174.7 97.0689 174.7 96.91Z" fill="#A6A6A6"/>
      <path d="M178.5 96.91C178.5 96.6702 178.571 96.4359 178.705 96.2367C178.838 96.0375 179.028 95.8824 179.249 95.7912C179.471 95.6999 179.715 95.6765 179.95 95.724C180.185 95.7715 180.4 95.8877 180.569 96.058C180.738 96.2282 180.852 96.4447 180.898 96.6801C180.943 96.9155 180.918 97.1591 180.825 97.38C180.732 97.6009 180.575 97.7892 180.375 97.9209C180.175 98.0527 179.94 98.122 179.7 98.12C179.541 98.12 179.385 98.0886 179.238 98.0277C179.092 97.9667 178.959 97.8775 178.848 97.765C178.736 97.6525 178.648 97.519 178.588 97.3723C178.529 97.2255 178.499 97.0684 178.5 96.91Z" fill="#A6A6A6"/>
      <path d="M182.3 96.91C182.3 96.6702 182.371 96.4359 182.504 96.2367C182.638 96.0375 182.827 95.8824 183.049 95.7912C183.271 95.6999 183.515 95.6765 183.75 95.724C183.985 95.7715 184.2 95.8877 184.369 96.058C184.538 96.2282 184.652 96.4447 184.698 96.6801C184.743 96.9155 184.718 97.1591 184.625 97.38C184.532 97.6009 184.375 97.7892 184.175 97.9209C183.974 98.0527 183.74 98.122 183.5 98.12C183.341 98.12 183.185 98.0886 183.038 98.0277C182.892 97.9667 182.759 97.8775 182.648 97.765C182.536 97.6525 182.448 97.519 182.388 97.3723C182.329 97.2255 182.299 97.0684 182.3 96.91Z" fill="#A6A6A6"/>
      <path d="M186.09 96.91C186.09 96.6707 186.161 96.4367 186.294 96.2377C186.427 96.0388 186.616 95.8837 186.837 95.7921C187.058 95.7005 187.301 95.6765 187.536 95.7232C187.771 95.7699 187.986 95.8852 188.155 96.0544C188.325 96.2236 188.44 96.4392 188.487 96.6739C188.533 96.9086 188.509 97.1519 188.418 97.373C188.326 97.5941 188.171 97.7831 187.972 97.9161C187.773 98.049 187.539 98.12 187.3 98.12C186.979 98.12 186.671 97.9925 186.444 97.7656C186.217 97.5387 186.09 97.2309 186.09 96.91Z" fill="#A6A6A6"/>
      <path d="M327.3 99.55H317.48V95H327.3V99.55ZM317.98 99.05H326.8V95.5H318L317.98 99.05Z" fill="#A6A6A6"/>
      <path d="M324.63 95.77H318.3V98.77H324.63V95.77Z" fill="#A6A6A6"/>
      <path d="M328.17 96.06H327.3V98.49H328.17V96.06Z" fill="#A6A6A6"/>
      <path d="M260.45 75.12H239.56V124.27H260.45V75.12Z" fill="#FB6524"/>
      <path d="M260.44 124.26C260.44 124.26 258.44 134.26 250 134.26C241.56 134.26 239.56 124.26 239.56 124.26H260.44Z" fill="#FB6524"/>
      <path opacity="0.1" d="M260.44 124.26C260.44 124.26 258.44 134.26 250 134.26C241.56 134.26 239.56 124.26 239.56 124.26H260.44Z" fill="black"/>
      <path d="M239.55 124.27H218.66L220.79 75.12H239.55V124.27Z" fill="white"/>
      <path d="M239.55 124.27L218.66 124.4H218.52C218.52 124.4 220.52 75.18 220.52 75.11V74.87H220.75H239.51H239.72C239.8 75.06 239.59 99.5 239.64 99.7L239.51 124.22L239.55 124.27ZM239.55 124.27L239.42 99.64L239.35 75.12L239.55 75.32L220.79 75.37L221.04 75.13C220.97 75.13 218.98 124.85 218.66 124.13L239.55 124.26V124.27Z" fill="#263238"/>
      <path d="M239.56 124.26C239.56 124.26 237.56 134.26 229.1 134.26C220.64 134.26 218.66 124.26 218.66 124.26H239.56Z" fill="#EBEBEB"/>
      <path d="M218.66 124.27H197.77L202.03 75.12H220.79L218.66 124.27Z" fill="#FB6524"/>
      <path d="M218.66 124.26C218.66 124.26 216.66 134.26 208.21 134.26C199.76 134.26 197.77 124.26 197.77 124.26H218.66Z" fill="#FB6524"/>
      <path opacity="0.1" d="M218.66 124.26C218.66 124.26 216.66 134.26 208.21 134.26C199.76 134.26 197.77 124.26 197.77 124.26H218.66Z" fill="black"/>
      <path d="M197.76 124.27H176.87L183.27 75.12H202.03L197.76 124.27Z" fill="white"/>
      <path d="M197.76 124.27L176.87 124.4H176.72C176.72 124.4 183 75.17 183 75.09V74.87H183.22H201.98H202.2V75.09L200 99.76L197.74 124.27H197.76ZM197.76 124.27L199.76 99.63L201.82 75.1L202.02 75.32L183.26 75.37L183.5 75.15C183.43 75.15 177.15 124.89 176.86 124.15L197.75 124.28L197.76 124.27Z" fill="#263238"/>
      <path d="M197.76 124.26C197.76 124.26 195.76 134.26 187.31 134.26C178.86 134.26 176.87 124.26 176.87 124.26H197.76Z" fill="#EBEBEB"/>
      <path d="M176.87 124.27H155.98L164.51 75.12H183.26L176.87 124.27Z" fill="#FB6524"/>
      <path d="M176.87 124.26C176.87 124.26 174.87 134.26 166.43 134.26C157.99 134.26 155.97 124.26 155.97 124.26H176.87Z" fill="#FB6524"/>
      <path opacity="0.1" d="M176.87 124.26C176.87 124.26 174.87 134.26 166.43 134.26C157.99 134.26 155.97 124.26 155.97 124.26H176.87Z" fill="black"/>
      <path d="M156 124.27H135.08L144.41 81.27C145.18 77.72 148.96 75.13 153.41 75.13H164.5L156 124.27Z" fill="white"/>
      <path d="M156 124.27L135.11 124.4H134.95V124.24C136.26 118.13 142.55 88.82 143.71 83.39C143.911 81.9836 144.311 80.613 144.9 79.32C147.11 75.32 152.01 74.61 156.15 74.89H164.5H164.75L164.7 75.12L160.36 99.74L156 124.27ZM156 124.27L160.14 99.62L164.33 75.08L164.53 75.32H156.18C152.18 75.08 147.51 75.72 145.35 79.54C144.78 80.7881 144.393 82.1117 144.2 83.47L142.41 91.63L135.24 124.28L135.11 124.12L156 124.25V124.27Z" fill="#263238"/>
      <path d="M156 124.26C156 124.26 154 134.26 145.56 134.26C137.12 134.26 135.12 124.26 135.12 124.26H156Z" fill="#EBEBEB"/>
      <path d="M260.45 124.27H281.34L279.21 75.12H260.45V124.27Z" fill="white"/>
      <path d="M260.45 124.27L281.34 124.14L281.21 124.27L279 75.13L279.25 75.37L260.49 75.32L260.69 75.12L260.62 99.64L260.49 124.27H260.45ZM260.45 124.27L260.32 99.75L260.25 75.12V74.92H260.46H279.22H279.45V75.16C279.45 75.34 281.45 124.29 281.45 124.45H281.31L260.42 124.32L260.45 124.27Z" fill="#263238"/>
      <path d="M281.34 124.26C281.34 124.26 279.34 134.26 270.9 134.26C262.46 134.26 260.44 124.26 260.44 124.26H281.34Z" fill="#EBEBEB"/>
      <path d="M281.34 124.27H302.23L297.97 75.12H279.21L281.34 124.27Z" fill="#FB6524"/>
      <path d="M302.23 124.26C302.23 124.26 300.23 134.26 291.79 134.26C283.35 134.26 281.34 124.26 281.34 124.26H302.23Z" fill="#FB6524"/>
      <path opacity="0.1" d="M302.23 124.26C302.23 124.26 300.23 134.26 291.79 134.26C283.35 134.26 281.34 124.26 281.34 124.26H302.23Z" fill="black"/>
      <path d="M302.24 124.27H323.13L316.73 75.12H297.97L302.24 124.27Z" fill="white"/>
      <path d="M302.24 124.27L323.13 124.14C322.84 125.04 316.59 74.95 316.49 75.14L316.73 75.36L298 75.32L298.2 75.1C299.25 87.35 301.28 112.01 302.27 124.27H302.24ZM302.24 124.27L300 99.76L297.79 75.14V74.92H298H316.76H317C317.07 75.28 323.27 124.07 323.33 124.45H323.18L302.29 124.32L302.24 124.27Z" fill="#263238"/>
      <path d="M323.13 124.26C323.13 124.26 321.13 134.26 312.69 134.26C304.25 134.26 302.24 124.26 302.24 124.26H323.13Z" fill="#EBEBEB"/>
      <path d="M323.13 124.27H344.02L335.49 75.12H316.74L323.13 124.27Z" fill="#FB6524"/>
      <path d="M344 124.26C344 124.26 342 134.26 333.54 134.26C325.08 134.26 323.1 124.26 323.1 124.26H344Z" fill="#FB6524"/>
      <path opacity="0.1" d="M344 124.26C344 124.26 342 134.26 333.54 134.26C325.08 134.26 323.1 124.26 323.1 124.26H344Z" fill="black"/>
      <path d="M344 124.27H364.89L355.56 81.27C354.79 77.72 351.01 75.13 346.56 75.13H335.5L344 124.27Z" fill="white"/>
      <path d="M344 124.27L364.89 124.14L364.76 124.3L357.59 91.65L355.8 83.49C355.607 82.1317 355.22 80.8081 354.65 79.56C352.49 75.74 347.84 75.1 343.82 75.34H335.47L335.67 75.1C337.81 87.33 342 112 344 124.27ZM344 124.27L339.61 99.77L335.3 75.15L335.25 74.92H335.5H343.85C348.72 74.53 354.65 75.92 355.85 81.37C357.35 88.37 363.45 116.79 365.05 124.27V124.43H364.89L344 124.27Z" fill="#263238"/>
      <path d="M364.91 124.26C364.91 124.26 362.91 134.26 354.47 134.26C346.03 134.26 344.03 124.26 344.03 124.26H364.91Z" fill="#EBEBEB"/>
      <path d="M298.61 220.53H201.4V317.74H298.61V220.53Z" fill="#FB6524"/>
      <path d="M281.94 267.06H218.06V258H281.94V267.06Z" fill="white"/>
      <path d="M278.38 265.14L276.27 298.75H223.74L221.62 265.14H278.38Z" fill="white"/>
      <path d="M256.79 261.51C256.79 262.853 256.392 264.166 255.646 265.282C254.9 266.399 253.839 267.269 252.598 267.783C251.358 268.297 249.992 268.431 248.675 268.169C247.358 267.908 246.148 267.261 245.199 266.311C244.249 265.362 243.602 264.152 243.34 262.835C243.078 261.517 243.213 260.152 243.727 258.912C244.241 257.671 245.111 256.61 246.228 255.864C247.344 255.118 248.657 254.72 250 254.72C251.8 254.723 253.526 255.439 254.798 256.712C256.071 257.984 256.787 259.71 256.79 261.51Z" fill="white"/>
      <path d="M241.06 272.41V291.75C241.063 291.978 241.02 292.203 240.935 292.414C240.85 292.625 240.723 292.817 240.563 292.979C240.403 293.141 240.213 293.269 240.003 293.357C239.793 293.445 239.567 293.49 239.34 293.49H239.06C238.872 293.489 238.686 293.45 238.513 293.377C238.34 293.304 238.184 293.198 238.052 293.064C237.92 292.93 237.816 292.772 237.745 292.598C237.674 292.424 237.639 292.238 237.64 292.05V272.41C237.643 272.034 237.793 271.675 238.059 271.409C238.324 271.143 238.684 270.993 239.06 270.99H239.62C239.808 270.987 239.995 271.022 240.169 271.092C240.344 271.162 240.503 271.267 240.637 271.399C240.771 271.531 240.877 271.688 240.95 271.862C241.023 272.035 241.06 272.222 241.06 272.41Z" fill="#FB6524"/>
      <path d="M251.7 272.41V291.75C251.703 291.978 251.66 292.203 251.575 292.414C251.49 292.625 251.363 292.817 251.203 292.979C251.043 293.141 250.853 293.269 250.643 293.357C250.433 293.445 250.208 293.49 249.98 293.49H249.7C249.512 293.489 249.327 293.45 249.154 293.377C248.981 293.304 248.824 293.198 248.692 293.064C248.56 292.93 248.456 292.772 248.385 292.598C248.314 292.424 248.279 292.238 248.28 292.05V272.41C248.285 272.036 248.437 271.679 248.702 271.415C248.968 271.152 249.326 271.003 249.7 271H250.26C250.447 270.997 250.633 271.032 250.807 271.101C250.981 271.171 251.14 271.274 251.274 271.405C251.407 271.536 251.514 271.693 251.587 271.865C251.66 272.037 251.699 272.223 251.7 272.41Z" fill="#FB6524"/>
      <path d="M262.34 272.41V291.75C262.343 291.978 262.3 292.203 262.215 292.414C262.13 292.625 262.003 292.817 261.843 292.979C261.683 293.141 261.493 293.269 261.283 293.357C261.073 293.445 260.848 293.49 260.62 293.49H260.34C260.152 293.489 259.966 293.45 259.793 293.377C259.62 293.304 259.464 293.198 259.332 293.064C259.2 292.93 259.096 292.772 259.025 292.598C258.954 292.424 258.919 292.238 258.92 292.05V272.41C258.923 272.034 259.073 271.675 259.339 271.409C259.604 271.143 259.964 270.993 260.34 270.99H260.9C261.088 270.987 261.275 271.022 261.449 271.092C261.624 271.162 261.783 271.267 261.917 271.399C262.051 271.531 262.157 271.688 262.23 271.862C262.303 272.035 262.34 272.222 262.34 272.41Z" fill="#FB6524"/>
      <path d="M268.63 260.96H265.63V243H234.37V260.96H231.37V240H268.63V260.96Z" fill="white"/>
      <path d="M310.354 231.244C316.26 224.752 315.784 214.703 309.292 208.797C302.8 202.892 292.75 203.368 286.845 209.86C280.94 216.352 281.416 226.402 287.908 232.307C294.4 238.212 304.449 237.736 310.354 231.244Z" fill="#455A64"/>
      <path d="M301.9 218.88C302.421 219.389 302.835 219.997 303.117 220.669C303.398 221.34 303.542 222.062 303.54 222.79C303.543 223.892 303.219 224.97 302.608 225.888C301.998 226.805 301.128 227.521 300.11 227.943C299.092 228.365 297.971 228.475 296.891 228.259C295.81 228.043 294.818 227.511 294.04 226.73C293.906 226.616 293.797 226.476 293.72 226.319C293.643 226.161 293.6 225.989 293.592 225.813C293.585 225.638 293.614 225.463 293.678 225.3C293.742 225.136 293.839 224.987 293.963 224.863C294.087 224.739 294.236 224.642 294.4 224.578C294.563 224.514 294.738 224.485 294.913 224.492C295.089 224.5 295.261 224.543 295.419 224.62C295.576 224.697 295.716 224.806 295.83 224.94C296.109 225.22 296.442 225.442 296.807 225.593C297.173 225.744 297.564 225.821 297.96 225.82C298.359 225.824 298.754 225.748 299.124 225.597C299.493 225.445 299.828 225.222 300.11 224.94C300.396 224.66 300.623 224.325 300.777 223.956C300.932 223.587 301.011 223.19 301.01 222.79C301.012 222.394 300.933 222.002 300.778 221.638C300.623 221.273 300.396 220.944 300.11 220.67C299.832 220.387 299.501 220.161 299.135 220.007C298.77 219.852 298.377 219.772 297.98 219.77C297.806 219.779 297.632 219.748 297.473 219.679C297.313 219.609 297.172 219.504 297.06 219.37C296.825 219.129 296.694 218.806 296.694 218.47C296.694 218.134 296.825 217.811 297.06 217.57L299.34 215.25H295.71C295.394 215.222 295.1 215.076 294.886 214.842C294.671 214.608 294.553 214.302 294.553 213.985C294.553 213.668 294.671 213.362 294.886 213.128C295.1 212.894 295.394 212.748 295.71 212.72H302.32C302.486 212.717 302.651 212.748 302.806 212.809C302.961 212.871 303.101 212.962 303.22 213.078C303.339 213.195 303.434 213.333 303.499 213.486C303.564 213.639 303.599 213.804 303.6 213.97C303.594 214.18 303.54 214.385 303.44 214.57C303.381 214.686 303.307 214.793 303.22 214.89L300.39 217.8C300.958 218.06 301.47 218.427 301.9 218.88Z" fill="white"/>
      <path d="M369.8 162.79C377.68 168.46 402.87 191.27 404.46 195.07C406.37 199.59 396.6 215.66 392.56 215.31C382.12 214.4 363.84 181.52 357.98 166.47C355.11 159.11 356.62 153.3 369.8 162.79Z" fill="#D3766A"/>
      <path d="M391.15 160.76C393.41 158.28 411.3 171.6 410.94 176.52C409.63 194.62 404.08 209.99 400.78 213.17C399.728 214.136 398.463 214.839 397.087 215.223C395.712 215.607 394.265 215.66 392.865 215.379C391.465 215.097 390.151 214.488 389.031 213.602C387.911 212.716 387.017 211.578 386.42 210.28C382.46 202 382.39 170.35 391.15 160.76Z" fill="#D3766A"/>
      <path d="M384.49 200.05C382.49 190.05 385.08 175.91 385.07 175.89C385.07 175.69 385.33 175.62 385.34 175.73C385.41 177.73 384.05 192.55 384.76 199.97C384.76 200 384.5 200.12 384.49 200.05Z" fill="#263238"/>
      <path d="M414.75 163.27C414.45 168.52 409.64 179 407.5 181.09C405.36 183.18 387.27 175.58 386.5 172.62C385.73 169.66 388.1 152.08 391.68 148.1C395.26 144.12 415.06 157.83 414.75 163.27Z" fill="#D3766A"/>
      <path d="M386.35 169.56C384.999 165.535 383.069 161.728 380.62 158.26C377.55 154.26 372.54 148.6 374.62 146.86C378.94 143.31 391.43 157.37 391.43 157.37C391.43 157.37 395.95 164.45 391.98 168.37C391.241 169.066 390.325 169.545 389.332 169.755C388.339 169.965 387.307 169.897 386.35 169.56Z" fill="#D3766A"/>
      <path d="M391.75 158.95L396.43 156.06L388.07 142.51L382.15 146.17L391.75 158.95Z" fill="#FB6524"/>
      <path d="M382.16 146.17L385.81 151.04L391.76 158.95L396.43 156.06L388.08 142.51L382.16 146.17Z" fill="#FB6524"/>
      <path opacity="0.1" d="M382.16 146.17L385.81 151.04L391.26 147.68L388.08 142.51L382.16 146.17Z" fill="black"/>
      <path d="M382.568 150.596L389.921 146.012L386.439 140.427L379.086 145.012L382.568 150.596Z" fill="#FB6524"/>
      <path d="M373.19 143.91L379.27 140.95L373.68 131.9L368.31 136L373.19 143.91Z" fill="#FB6524"/>
      <path d="M375.52 128.29L383.76 141.65L394.07 136.88L421.46 131.32L401.66 99.23L384.41 121.22L375.52 128.29Z" fill="#FB6524"/>
      <path d="M406.9 118.15C412.39 127.06 419.17 132.85 422.03 131.08C424.89 129.31 422.76 120.67 417.26 111.76C411.76 102.85 405 97.07 402.13 98.83C399.26 100.59 401.41 109.25 406.9 118.15Z" fill="#FB6524"/>
      <path opacity="0.2" d="M407.94 117.13C412.81 125.04 418.66 130.27 421.01 128.83C423.36 127.39 421.3 119.83 416.42 111.9C411.54 103.97 405.69 98.76 403.35 100.2C401.01 101.64 403.06 109.23 407.94 117.13Z" fill="black"/>
      <path d="M408.63 116.71C409.28 117.76 409.94 118.76 410.63 119.71C411.334 119.799 412.05 119.687 412.693 119.387C413.337 119.087 413.882 118.61 414.266 118.013C414.65 117.415 414.857 116.721 414.863 116.011C414.869 115.301 414.674 114.604 414.3 114L413.37 112.49C412.999 111.887 412.465 111.401 411.83 111.089C411.195 110.776 410.484 110.649 409.78 110.722C409.076 110.795 408.407 111.065 407.849 111.502C407.292 111.939 406.869 112.524 406.63 113.19C407.23 114.34 407.9 115.52 408.63 116.71Z" fill="#FB6524"/>
      <path d="M379.04 145.59L390.79 139.87L379.99 122.36L369.6 130.29L379.04 145.59Z" fill="#FB6524"/>
      <path opacity="0.2" d="M379.04 145.59L390.79 139.87L379.99 122.36L369.6 130.29L379.04 145.59Z" fill="black"/>
      <path opacity="0.2" d="M401.52 102.36C401.352 103.774 401.44 105.207 401.78 106.59C402.094 107.977 402.515 109.338 403.04 110.66C403.29 111.33 403.59 111.97 403.87 112.66C404.15 113.35 404.49 113.94 404.82 114.57C405.15 115.2 405.48 115.83 405.82 116.44C406.16 117.05 406.53 117.67 406.9 118.27C408.39 120.694 410.093 122.98 411.99 125.1C412.948 126.152 413.999 127.116 415.13 127.98C416.244 128.873 417.536 129.518 418.92 129.87C417.575 129.457 416.326 128.777 415.25 127.87C414.168 126.977 413.165 125.993 412.25 124.93C410.392 122.8 408.72 120.515 407.25 118.1C405.735 115.715 404.417 113.21 403.31 110.61C402.754 109.315 402.303 107.977 401.96 106.61C401.562 105.231 401.413 103.792 401.52 102.36Z" fill="black"/>
      <path d="M412.91 94.36C412.42 95.21 411.97 96.08 411.53 96.96C411.09 97.84 410.67 98.72 410.26 99.61C409.85 100.5 409.45 101.4 409.08 102.3L408.52 103.67C408.35 104.13 408.16 104.58 408 105.05C408.25 104.62 408.48 104.19 408.72 103.76L409.39 102.45C409.83 101.57 410.25 100.69 410.66 99.8C411.07 98.91 411.47 98.01 411.84 97.1C412.21 96.19 412.59 95.29 412.91 94.36Z" fill="#263238"/>
      <path d="M418.43 123C419.53 122.87 420.62 122.68 421.71 122.49C422.8 122.3 423.88 122.08 424.96 121.86C426.04 121.64 427.13 121.4 428.2 121.13L429.81 120.73C430.34 120.58 430.88 120.45 431.41 120.28L429.76 120.52L428.13 120.8C427.04 120.98 425.96 121.2 424.87 121.43C423.78 121.66 422.71 121.89 421.64 122.16C420.57 122.43 419.49 122.73 418.43 123Z" fill="#263238"/>
      <path d="M435.93 111.94C434.37 112.42 432.83 112.94 431.3 113.48C429.77 114.02 428.24 114.57 426.71 115.13C425.18 115.69 423.71 116.27 422.16 116.88L419.9 117.79C419.15 118.1 418.39 118.41 417.65 118.74C418.43 118.5 419.2 118.24 419.97 117.99L422.28 117.2C423.82 116.67 425.34 116.11 426.87 115.55C428.4 114.99 429.87 114.4 431.42 113.8C432.97 113.2 434.44 112.59 435.93 111.94Z" fill="#263238"/>
      <path d="M421.47 91.31C420.47 92.65 419.59 94.02 418.67 95.39C417.75 96.76 416.86 98.15 415.98 99.54C415.1 100.93 414.22 102.32 413.37 103.74L412.09 105.86C411.68 106.57 411.26 107.28 410.86 108C411.34 107.33 411.8 106.65 412.28 106L413.66 104C414.57 102.63 415.46 101.24 416.35 99.85C417.24 98.46 418.11 97.07 418.96 95.65C419.81 94.23 420.66 92.75 421.47 91.31Z" fill="#263238"/>
      <path d="M414.73 113.15C416.39 112.22 418.02 111.24 419.65 110.25C421.28 109.26 422.88 108.25 424.5 107.25C426.12 106.25 427.71 105.25 429.3 104.15L431.69 102.58C432.47 102.04 433.26 101.51 434.04 100.96C433.21 101.43 432.39 101.96 431.57 102.39L429.12 103.87C427.49 104.87 425.89 105.87 424.27 106.87C422.65 107.87 421.06 108.87 419.47 109.96C417.88 111.05 416.29 112.06 414.73 113.15Z" fill="#263238"/>
      <path d="M387.37 155.33C388.67 157.8 390.83 154.85 390.83 154.85C390.83 154.85 388.74 159.85 391.07 161.85C393.4 163.85 395.79 160.14 395.97 159.85C395.86 160.11 394.07 164.74 396.61 166.53C399.15 168.32 401.12 164.3 401.24 164.03C401.24 164.32 400.97 168.03 403.24 168.97C407.09 170.54 407.98 161.02 410.67 158.97C410.67 158.97 414.67 163.12 414.78 163.22C414.89 163.32 415.78 154.22 412.86 150.71C412.44 150.199 411.858 149.847 411.21 149.713C410.563 149.578 409.889 149.669 409.3 149.97C409.3 149.97 409.95 147.1 407.99 145.62C406.99 144.87 404.89 145.81 404.89 145.81C405.003 145.151 404.933 144.474 404.686 143.853C404.439 143.232 404.025 142.691 403.49 142.29C402.04 141.29 400.16 142.42 400.16 142.42C400.16 142.42 399.54 139.99 397.73 139.49C394.07 138.52 384.59 150.06 387.37 155.33Z" fill="#D3766A"/>
      <path d="M399.81 142.6C399.81 142.6 399.81 142.51 399.76 142.54C395.541 145.676 392.378 150.022 390.69 155C390.64 155.16 390.88 155.31 390.97 155.14C393.291 150.552 396.269 146.328 399.81 142.6Z" fill="#263238"/>
      <path d="M404.88 145.79C404.88 145.79 404.88 145.68 404.8 145.72C400.2 149.231 397.008 154.271 395.8 159.93C395.8 160.13 395.93 160.34 395.99 160.12C397 156.31 401.51 149.24 404.88 145.79Z" fill="#263238"/>
      <path d="M401.3 164.51C402.55 158.98 405.44 154.41 409.09 150.14C409.09 150.14 409.09 150.03 409 150.06C406.65 151.66 404.61 154.63 403.21 157.06C401.876 159.301 401.152 161.852 401.11 164.46C401.11 164.57 401.27 164.62 401.3 164.51Z" fill="#263238"/>
      <path d="M390.74 154.88C390.68 155.07 391.02 154.95 390.97 155.14C390.75 155.96 389.82 160.96 391.43 161.8C392.72 162.48 394.52 161.21 395.02 160.45L396.28 158.55L395.76 160.78C395.52 161.761 395.443 162.774 395.53 163.78C395.545 164.25 395.659 164.712 395.864 165.136C396.069 165.56 396.361 165.936 396.72 166.24C397.062 166.513 397.487 166.662 397.925 166.662C398.363 166.662 398.788 166.513 399.13 166.24C399.879 165.611 400.457 164.803 400.81 163.89L401.88 161.41L401.69 164.09C401.635 164.839 401.675 165.591 401.81 166.33C401.915 167.034 402.224 167.691 402.7 168.22C402.924 168.448 403.208 168.61 403.519 168.686C403.83 168.761 404.156 168.749 404.46 168.65C405.108 168.33 405.649 167.83 406.02 167.21C406.848 165.882 407.571 164.491 408.18 163.05C408.52 162.33 408.85 161.61 409.18 160.91C409.511 160.179 409.995 159.527 410.6 159C410.066 159.58 409.651 160.26 409.38 161C409.07 161.73 408.79 162.47 408.51 163.21C407.984 164.724 407.314 166.184 406.51 167.57C406.095 168.335 405.461 168.958 404.69 169.36C404.248 169.535 403.765 169.577 403.299 169.481C402.834 169.385 402.407 169.155 402.07 168.82C401.462 168.179 401.058 167.371 400.91 166.5C400.743 165.685 400.686 164.851 400.74 164.02L401.61 164.21C401.204 165.305 400.514 166.271 399.61 167.01C399.106 167.406 398.481 167.618 397.84 167.61C397.21 167.572 396.604 167.35 396.1 166.97C395.643 166.587 395.273 166.112 395.013 165.576C394.752 165.04 394.608 164.455 394.59 163.86C394.509 162.744 394.61 161.623 394.89 160.54L395.63 160.87C394.96 161.81 393.1 163.44 390.97 162.65C388.43 161.22 390.32 155.84 390.74 154.88Z" fill="#263238"/>
      <path d="M390.83 154.85C390.409 155.252 389.931 155.589 389.41 155.85C389.202 155.949 388.973 155.992 388.743 155.976C388.514 155.961 388.292 155.886 388.1 155.76C387.726 155.416 387.459 154.972 387.33 154.48C387.16 153.94 387.01 153.37 386.88 152.78C386.7 153.372 386.649 153.996 386.73 154.61C386.786 155.3 387.113 155.94 387.64 156.39C387.94 156.601 388.298 156.715 388.665 156.715C389.032 156.715 389.39 156.601 389.69 156.39C390.228 156.015 390.629 155.474 390.83 154.85Z" fill="#263238"/>
      <path d="M345.29 448.44C343.29 447.21 303.03 398.44 302.68 395.57C302.63 395.18 308.76 389.66 316.54 382.73C316.94 382.37 346.08 354.17 346.08 354.17L371.08 384.36L343.74 412.26C343.74 412.26 350.8 437.83 350.86 440.99C350.92 444.15 347.27 449.68 345.29 448.44Z" fill="#D3766A"/>
      <path d="M346.65 422.37C346.58 422.21 346.56 422.12 346.59 422.08C346.616 422.175 346.636 422.272 346.65 422.37Z" fill="#FB6524"/>
      <path d="M350.87 441C350.92 444.14 347.26 449.69 345.29 448.45C343.32 447.21 303.03 398.45 302.68 395.58C302.62 395.19 308.75 389.67 316.54 382.74L322.8 376.74L348.57 407.39L343.75 412.31C343.75 412.31 350.81 437.83 350.87 441Z" fill="#EBEBEB"/>
      <path d="M346.85 447.93C344.85 446.68 304.26 397.26 303.91 394.39C303.76 393.31 310.62 386.97 319.12 379.56L319.39 379.33L345.93 410.93L345.38 411.36C345.38 411.36 352.45 437.23 352.51 440.42C352.57 443.61 348.84 449.19 346.85 447.93Z" fill="#FB6524"/>
      <path d="M348.3 445.8C341.67 437.3 314.09 403.64 306.89 395.74C306.83 395.68 306.78 395.74 306.84 395.79C313.23 404.36 341.07 437.79 348.17 445.91C348.32 446.08 348.44 446 348.3 445.8Z" fill="white"/>
      <path d="M347.49 414.12C345.41 410.33 341.61 406.74 337.22 406.12C337.05 406.12 337.02 406.34 337.14 406.42C340.721 408.727 344.047 411.409 347.06 414.42C347.105 414.46 347.163 414.483 347.223 414.487C347.283 414.49 347.343 414.473 347.393 414.438C347.442 414.404 347.479 414.354 347.496 414.296C347.514 414.238 347.512 414.176 347.49 414.12Z" fill="white"/>
      <path d="M348 417.65C345.92 413.87 342.13 410.28 337.73 409.65C337.56 409.65 337.53 409.87 337.65 409.95C341.237 412.254 344.566 414.936 347.58 417.95C347.626 417.985 347.682 418.004 347.74 418.005C347.797 418.006 347.854 417.989 347.901 417.955C347.948 417.922 347.983 417.874 348.001 417.819C348.019 417.764 348.018 417.705 348 417.65Z" fill="white"/>
      <path d="M348.52 421.19C346.44 417.41 342.64 413.81 338.25 413.19C338.08 413.19 338.05 413.41 338.17 413.48C341.754 415.787 345.083 418.469 348.1 421.48C348.145 421.513 348.2 421.532 348.256 421.532C348.312 421.533 348.367 421.517 348.413 421.485C348.46 421.453 348.495 421.407 348.514 421.354C348.533 421.302 348.535 421.244 348.52 421.19Z" fill="white"/>
      <path d="M349.24 447.23C349.62 448.4 346.49 450.65 345.73 450.23C344.97 449.81 301 395.67 300.41 394.58C299.82 393.49 303.41 391.14 304.47 392.06C305.53 392.98 349.24 447.23 349.24 447.23Z" fill="#263238"/>
      <path d="M320.56 392C319.41 387 311.56 388.73 312.7 393.79C313.84 398.85 321.71 397.1 320.56 392Z" fill="white"/>
      <path d="M366.11 323.7C363.87 334.7 323.4 372.75 323.4 372.75L355.29 409.57C355.29 409.57 414.98 350.71 411.59 326.35C408.64 305.15 376.91 219.93 376.91 219.93H340.3C340.3 219.93 368.3 312.86 366.11 323.7Z" fill="#37474F"/>
      <path d="M366.11 323.7C363.87 334.7 323.4 372.75 323.4 372.75L355.29 409.57C355.29 409.57 414.98 350.71 411.59 326.35C408.64 305.15 376.91 219.93 376.91 219.93H340.3C340.3 219.93 368.3 312.86 366.11 323.7Z" fill="#37474F"/>
      <path d="M358.92 400.81C354.68 395.54 355.97 396.99 351.68 391.76C349.57 389.2 335.74 372.41 333.36 370.36C333.31 370.36 333.22 370.36 333.25 370.45C334.85 373.15 348.97 389.71 351.13 392.23C355.52 397.37 354.32 395.85 358.77 400.94C358.85 401 359 400.91 358.92 400.81Z" fill="#263238"/>
      <path d="M370.65 318.33C370.13 315.58 369.53 312.83 368.95 310.09C367.757 304.49 366.497 298.907 365.17 293.34C362.543 282.3 359.677 271.32 356.57 260.4C353.45 249.45 350.15 238.51 346.35 227.77C345.89 226.44 345.35 225.12 344.92 223.77C344.92 223.67 344.72 223.71 344.76 223.77C348.05 234.53 351.44 245.26 354.52 256.08C357.6 266.9 360.44 277.69 363.12 288.57C364.46 294.04 365.71 299.57 366.94 305.01C367.54 307.71 368.127 310.413 368.7 313.12C369.414 315.862 369.925 318.653 370.23 321.47C370.324 324.141 369.572 326.773 368.08 328.99C366.71 331.24 365.08 333.35 363.54 335.47C360.41 339.71 335.89 366.92 334.93 367.83C334.85 367.9 334.93 368 335.04 367.94C339.04 364.35 359.04 342.94 362.26 338.73C363.85 336.64 365.39 334.51 366.91 332.37C368.432 330.424 369.658 328.264 370.55 325.96C371.234 323.467 371.268 320.84 370.65 318.33Z" fill="#263238"/>
      <path d="M370.58 219.92L363.93 307.14C357.59 277.29 340.31 219.92 340.31 219.92H370.58Z" fill="#263238"/>
      <path d="M432 459.9C429.8 460.65 366.51 460.82 364.1 459.29C363.76 459.09 363.39 450.85 362.97 440.43C362.97 439.9 355.21 397.22 355.21 397.22L394.42 397.03L403.06 438.13C403.06 438.13 427.31 448.9 429.79 450.85C432.27 452.8 434.18 459.16 432 459.9Z" fill="#D3766A"/>
      <path d="M432 459.9C429.79 460.65 366.51 460.82 364.1 459.29C363.76 459.09 363.38 450.85 362.96 440.43C362.96 440.28 362.37 436.96 361.52 432.22H401.81L403.05 438.13C403.05 438.13 427.3 448.9 429.78 450.85C432.26 452.8 434.18 459.16 432 459.9Z" fill="#EBEBEB"/>
      <path d="M412.68 442.3C412.52 442.3 412.43 442.21 412.42 442.16L412.68 442.3Z" fill="#FB6524"/>
      <path d="M432.29 459.62C430.05 460.36 366.09 460.07 363.67 458.51C362.73 457.93 362.23 448.61 361.95 437.33V437L403.21 436.72V437.42C403.21 437.42 427.64 448.49 430.13 450.48C432.62 452.47 434.52 458.88 432.29 459.62Z" fill="#FB6524"/>
      <path d="M431.57 457.13C420.79 456.82 377.28 456.57 366.57 457.08C366.48 457.08 366.48 457.14 366.57 457.15C377.24 457.69 420.75 457.59 431.57 457.31C431.8 457.3 431.8 457.14 431.57 457.13Z" fill="white"/>
      <path d="M406.66 437.54C402.42 436.73 397.23 437.36 393.97 440.37C393.85 440.48 393.97 440.66 394.15 440.62C398.21 439.329 402.398 438.477 406.64 438.08C406.712 438.083 406.781 438.057 406.834 438.008C406.886 437.959 406.917 437.892 406.92 437.82C406.923 437.748 406.897 437.679 406.848 437.626C406.799 437.574 406.732 437.543 406.66 437.54Z" fill="white"/>
      <path d="M409.71 439.4C405.47 438.59 400.28 439.22 397.03 442.23C396.9 442.34 397.03 442.52 397.2 442.48C401.261 441.191 405.448 440.339 409.69 439.94C409.725 439.941 409.761 439.936 409.794 439.923C409.827 439.911 409.858 439.892 409.884 439.868C409.91 439.844 409.931 439.815 409.946 439.783C409.96 439.75 409.969 439.715 409.97 439.68C409.971 439.645 409.966 439.609 409.953 439.576C409.941 439.543 409.922 439.512 409.898 439.486C409.874 439.46 409.845 439.439 409.812 439.424C409.78 439.41 409.745 439.401 409.71 439.4Z" fill="white"/>
      <path d="M412.77 441.26C408.53 440.45 403.33 441.08 400.08 444.09C399.95 444.2 400.08 444.38 400.26 444.34C404.32 443.049 408.508 442.197 412.75 441.8C412.822 441.803 412.891 441.777 412.944 441.728C412.996 441.679 413.027 441.612 413.03 441.54C413.033 441.468 413.007 441.399 412.958 441.346C412.909 441.294 412.842 441.263 412.77 441.26Z" fill="white"/>
      <path d="M433.27 457.33C434.42 457.77 434.15 461.62 433.32 461.92C432.49 462.22 362.76 461.6 361.58 461.33C360.4 461.06 360.85 456.81 362.23 456.59C363.61 456.37 433.27 457.33 433.27 457.33Z" fill="#263238"/>
      <path d="M372.47 444.19C367.85 441.85 364.2 449.03 368.84 451.38C373.48 453.73 377.11 446.53 372.47 444.19Z" fill="white"/>
      <path d="M373.38 219.93C371.954 225.946 370.902 232.044 370.23 238.19C369.47 245.85 368.51 252.63 367.49 253.47C366.47 254.31 360.55 258.14 358.6 259.33C358.6 259.33 376.6 314.01 381.29 332.23C388.04 358.65 404.17 428.74 404.17 428.74H360C360 428.74 348.07 362.46 342.35 337.31C337.42 315.73 318.62 251 318.62 251C311.78 234.18 323.31 219.89 323.31 219.89C323.31 219.89 373.38 219.9 373.38 219.93Z" fill="#37474F"/>
      <path d="M400.06 420.38C393.53 420.38 395.41 420.26 388.88 420.3C385.69 420.3 364.71 420.45 361.73 421.01C361.67 421.01 361.67 421.14 361.73 421.15C364.73 421.61 385.73 421.15 388.88 421.02C395.41 420.85 393.53 420.82 400.05 420.58C400.077 420.581 400.103 420.572 400.122 420.554C400.142 420.536 400.154 420.512 400.155 420.485C400.156 420.459 400.147 420.433 400.129 420.413C400.111 420.393 400.087 420.381 400.06 420.38Z" fill="#263238"/>
      <path d="M370.54 251.31C366.357 253.629 362.382 256.306 358.66 259.31C358.644 259.321 358.631 259.334 358.62 259.35C358.61 259.366 358.602 259.383 358.599 259.402C358.595 259.42 358.595 259.439 358.598 259.458C358.602 259.477 358.609 259.494 358.62 259.51C358.63 259.526 358.644 259.539 358.66 259.55C358.675 259.56 358.693 259.568 358.712 259.571C358.73 259.575 358.749 259.575 358.768 259.571C358.786 259.568 358.804 259.561 358.82 259.55C362.82 257.03 366.97 254.55 370.82 251.83C370.889 251.793 370.94 251.73 370.963 251.655C370.985 251.58 370.977 251.499 370.94 251.43C370.903 251.361 370.84 251.31 370.765 251.287C370.69 251.265 370.609 251.273 370.54 251.31Z" fill="#263238"/>
      <path d="M367.25 221.57C367.25 221.42 366.95 221.43 366.98 221.57C367.727 226.382 368.231 231.228 368.49 236.09C368.64 238.35 368.76 250.9 364.76 250.61C364.1 250.61 363.67 249.97 363.42 249.02C363.233 247.745 363.206 246.452 363.34 245.17C363.34 242.53 363.34 239.9 363.13 237.27C362.829 232.366 362.021 227.507 360.72 222.77C360.708 222.742 360.688 222.719 360.661 222.705C360.635 222.691 360.604 222.687 360.574 222.693C360.545 222.698 360.518 222.714 360.499 222.738C360.48 222.761 360.47 222.79 360.47 222.82C361.631 229.507 362.279 236.274 362.41 243.06C362.41 246.32 361.57 251.38 364.98 251.36C368.39 251.34 369.41 242.93 369.49 240.66C369.574 234.226 368.821 227.809 367.25 221.57Z" fill="#263238"/>
      <path d="M364.92 250.94C364.704 251.822 364.547 252.717 364.45 253.62C364.321 254.331 364.321 255.059 364.45 255.77C364.461 255.798 364.48 255.823 364.505 255.84C364.53 255.857 364.56 255.866 364.59 255.866C364.62 255.866 364.65 255.857 364.675 255.84C364.7 255.823 364.719 255.798 364.73 255.77C365.03 255.08 365.226 254.348 365.31 253.6C365.48 252.77 365.67 251.94 365.81 251.1C365.831 250.982 365.805 250.86 365.736 250.762C365.668 250.663 365.563 250.596 365.445 250.575C365.327 250.554 365.205 250.58 365.107 250.649C365.009 250.717 364.941 250.822 364.92 250.94Z" fill="#263238"/>
      <path d="M356.14 405.82C356.02 404.82 355.89 403.82 355.75 402.82C355.48 400.91 355.16 399.01 354.86 397.1C354.27 393.27 353.62 389.46 352.94 385.65C351.59 378.04 350.03 370.46 348.38 362.92C347.45 358.65 346.38 354.44 345.23 350.22C345.15 349.93 344.74 350.07 344.79 350.36C345.48 354.21 346.15 358.07 346.94 361.91C347.73 365.75 348.51 369.57 349.27 373.4C350.77 380.97 352.17 388.57 353.67 396.15C354.1 398.29 354.48 400.43 354.9 402.57C355.11 403.64 355.32 404.7 355.55 405.77C355.78 406.84 356.11 407.87 356.29 408.94C356.29 409.04 356.45 408.99 356.44 408.94C356.27 407.89 356.25 406.85 356.14 405.82Z" fill="#263238"/>
      <path d="M385.83 348.61C384.39 341.46 382.46 334.41 380.52 327.39C376.6 313.26 372 299.34 367.4 285.39L363.4 273.39C362.73 271.39 359.49 260.58 359.09 259.55C359.04 259.45 358.89 259.49 358.92 259.55C359.85 263.23 361.12 268.6 362.2 272.25C363.28 275.9 364.42 279.43 365.54 283.02C367.74 290.07 369.98 297.11 372.11 304.18C376.33 318.18 380.53 332.11 383.95 346.31C384.95 350.31 385.87 354.25 386.74 358.25C387.61 362.25 388.37 366.25 389.2 370.25C389.2 370.277 389.21 370.302 389.229 370.321C389.248 370.339 389.273 370.35 389.3 370.35C389.326 370.35 389.352 370.339 389.371 370.321C389.389 370.302 389.4 370.277 389.4 370.25C388.71 363 387.26 355.77 385.83 348.61Z" fill="#263238"/>
      <path d="M359.06 386C356.69 372.37 353.85 358.82 350.7 345.34C347.55 331.86 344.02 318.34 340.14 305C336.26 291.66 332 278.4 327.35 265.29C325.17 259.08 322.5 252.93 321.51 246.41C320.125 238.039 321.53 229.444 325.51 221.95C325.516 221.94 325.52 221.928 325.521 221.916C325.522 221.904 325.522 221.892 325.518 221.88C325.515 221.869 325.51 221.858 325.502 221.848C325.495 221.839 325.485 221.831 325.475 221.825C325.464 221.819 325.453 221.815 325.441 221.814C325.429 221.812 325.417 221.813 325.405 221.817C325.394 221.82 325.383 221.825 325.373 221.833C325.364 221.84 325.356 221.85 325.35 221.86C321.46 227.67 317.13 239.71 324.35 259.02C326.75 265.46 328.82 272.02 330.95 278.53C333.137 285.243 335.233 291.973 337.24 298.72C341.24 312.11 344.9 325.59 348.24 339.16C351.58 352.73 354.49 366.39 357.08 380.11C359.67 393.83 360.71 398.53 362.92 412.36C363.21 414.16 363.44 415.97 363.68 417.78C363.68 417.88 363.85 417.88 363.84 417.78C362.07 404.07 361.42 399.59 359.06 386Z" fill="#263238"/>
      <path d="M378.63 221.23L375.78 202.33C375.78 202.33 381.39 195.76 377.83 187.97C376 184 364.67 162.6 358 157.7C351.73 153.1 343.86 157.44 340.91 161.39C332.42 172.76 326.25 183.67 321.34 220.92L378.63 221.23Z" fill="#FB6524"/>
      <path d="M366.11 199.32C363.228 199.581 360.37 200.059 357.56 200.75C356.17 201.11 354.8 201.54 353.44 201.99C352.76 202.22 352.07 202.42 351.39 202.62C350.673 202.849 349.931 202.99 349.18 203.04C349.12 203.04 349.12 203.14 349.18 203.15C349.887 203.178 350.595 203.162 351.3 203.1C351.975 203.015 352.643 202.884 353.3 202.71C354.72 202.36 356.11 201.94 357.53 201.57C360.37 200.83 363.22 200.09 366.11 199.57C366.28 199.53 366.24 199.3 366.11 199.32Z" fill="#263238"/>
      <path d="M373.24 201.85C370.865 201.604 368.477 201.497 366.09 201.53C364.9 201.53 363.71 201.63 362.52 201.75C361.96 201.81 361.39 201.87 360.82 201.95C360.25 202.03 359.59 202.19 358.97 202.27C358.957 202.27 358.944 202.275 358.935 202.285C358.925 202.294 358.92 202.307 358.92 202.32C358.92 202.333 358.925 202.346 358.935 202.355C358.944 202.365 358.957 202.37 358.97 202.37C359.56 202.32 360.16 202.37 360.75 202.37H362.53L366.1 202.27C368.48 202.18 370.86 202.12 373.24 202.11C373.257 202.11 373.274 202.107 373.29 202.1C373.305 202.094 373.32 202.084 373.332 202.072C373.344 202.06 373.353 202.046 373.36 202.03C373.367 202.014 373.37 201.997 373.37 201.98C373.37 201.963 373.367 201.946 373.36 201.93C373.353 201.914 373.344 201.9 373.332 201.888C373.32 201.876 373.305 201.866 373.29 201.86C373.274 201.853 373.257 201.85 373.24 201.85Z" fill="#263238"/>
      <path d="M340.52 161C322.63 177.87 306.06 205.73 307.24 220.36C308.41 234.81 313.31 232.95 314.73 232.73C323.4 231.37 340.3 189.34 345.07 172.4C349.84 155.46 344.94 156.83 340.52 161Z" fill="#D3766A"/>
      <path d="M345.65 144.62C342.54 144.53 335.28 148.1 333.47 152.56C331.66 157.02 333.09 163.41 334.53 163.79C335.97 164.17 341.53 162.61 345.04 159.73C348.55 156.85 347.18 144.67 345.65 144.62Z" fill="#263238"/>
      <path d="M345.66 144.64C345.75 144.64 345.73 144.43 345.66 144.45C343.04 144.87 340.41 146.28 338.06 147.45C336.095 148.309 334.33 149.57 332.88 151.15C332.22 151.941 331.739 152.865 331.472 153.86C331.204 154.854 331.156 155.895 331.33 156.91C331.636 159.391 332.573 161.753 334.05 163.77C334.1 163.82 334.21 163.77 334.17 163.71C332.17 159.59 330.09 154.48 333.85 150.71C337 147.52 341.63 146.34 345.66 144.64Z" fill="#263238"/>
      <path d="M334.75 162.58C332.21 163.41 326.37 169.65 326.18 174.82C325.99 179.99 331.38 186.55 332.76 186.82C334.14 187.09 339 181.27 340.21 177.42C341.03 174.86 339.52 172.33 338.44 169.73C336.85 165.92 335.14 162.45 334.75 162.58Z" fill="#263238"/>
      <path d="M341.66 176.53C341.48 171.33 337.66 166.87 335.09 162.64H335.01C336.79 166.83 340.16 170.27 341.1 174.83C341.435 176.155 341.445 177.542 341.13 178.872C340.815 180.202 340.184 181.436 339.29 182.47C337.63 184.28 335.29 185.4 333.22 186.62C333.08 186.7 333.22 186.9 333.36 186.82C337.56 184.55 341.84 181.89 341.66 176.53Z" fill="#263238"/>
      <path d="M334.27 163.15C334.39 163.06 334.27 162.87 334.15 162.96C330.77 165.41 326.8 168.82 325.52 172.96C324.14 177.43 327.1 181.28 329.71 184.58C329.71 184.58 329.81 184.58 329.78 184.58C327.19 181.21 324.32 176.96 326.01 172.58C327.47 168.69 331.1 165.53 334.27 163.15Z" fill="#263238"/>
      <path d="M333.58 185.68C331.13 189.25 328.3 194.36 330.29 197.8C332.28 201.24 338.42 205.27 338.83 205.13C339.24 204.99 344.49 198.72 344.38 193.87C344.27 189.02 333.9 185.21 333.58 185.68Z" fill="#263238"/>
      <path d="M339.24 205.32C335.66 203.86 331.32 201.67 329.84 197.81C328.36 193.95 331.19 189.25 333.22 186.26H333.17C330.83 189.15 328.17 193.91 329.46 197.74C330.75 201.57 335.46 204.57 339.2 205.51C339.28 205.48 339.31 205.35 339.24 205.32Z" fill="#263238"/>
      <path d="M338.66 204.78C337.976 206.171 337.585 207.687 337.511 209.235C337.437 210.783 337.682 212.33 338.23 213.78C340.47 219.53 337.88 219.67 333 224.45C328.86 228.45 331 234.51 335.2 235.65C335.2 235.65 331.73 233.2 334.02 229.65C336.75 225.39 348.07 224.2 349.24 216.07C350.77 205.52 338.66 204.78 338.66 204.78Z" fill="#263238"/>
      <path d="M338 208.42C338 208.31 337.83 208.27 337.81 208.42C337.539 209.467 337.461 210.555 337.58 211.63C337.75 213.02 338.44 214.24 338.7 215.63C339.36 219.11 335.84 221.07 333.43 222.74C330.21 224.97 328.72 228.64 330.62 232.31C330.62 232.36 330.73 232.31 330.7 232.31C330.127 231.172 329.875 229.899 329.972 228.628C330.069 227.358 330.511 226.138 331.25 225.1C332.73 223.1 335.25 222.22 336.96 220.52C338.16 219.35 339.2 217.93 339.04 216.18C338.873 215.234 338.605 214.309 338.24 213.42C337.729 211.802 337.646 210.079 338 208.42Z" fill="#263238"/>
      <path d="M339.92 204.89C339.87 204.89 339.86 204.96 339.92 204.97C342.92 205.72 346.09 206.41 348.27 208.84C350.62 211.45 350.85 215.33 349.59 218.5C348.17 222.05 344.8 222.75 341.59 223.91C339.19 224.6 337.018 225.919 335.3 227.73C335.25 227.79 335.35 227.87 335.41 227.82C338.28 225.07 341.92 224.25 345.5 222.82C346.854 222.328 348.042 221.463 348.927 220.326C349.811 219.188 350.357 217.824 350.5 216.39C351.49 209.58 346.06 205.73 339.92 204.89Z" fill="#263238"/>
      <path d="M341.52 140.3C341.281 140.187 341.008 140.168 340.755 140.246C340.503 140.324 340.288 140.494 340.154 140.722C340.02 140.95 339.976 141.219 340.031 141.478C340.086 141.737 340.235 141.966 340.45 142.12C340.689 142.233 340.962 142.252 341.215 142.174C341.467 142.096 341.682 141.926 341.816 141.698C341.95 141.47 341.994 141.201 341.939 140.942C341.884 140.683 341.735 140.454 341.52 140.3Z" fill="#FB6524"/>
      <path d="M342.92 141.69C342.68 141.566 342.401 141.538 342.142 141.613C341.882 141.687 341.66 141.858 341.522 142.091C341.384 142.323 341.34 142.6 341.399 142.864C341.459 143.127 341.616 143.359 341.84 143.51C342.08 143.634 342.359 143.662 342.619 143.587C342.878 143.513 343.1 143.342 343.238 143.109C343.376 142.877 343.42 142.6 343.361 142.336C343.302 142.073 343.144 141.841 342.92 141.69Z" fill="#FB6524"/>
      <path d="M344.54 142.83C344.301 142.717 344.028 142.698 343.775 142.776C343.523 142.854 343.308 143.024 343.174 143.252C343.04 143.48 342.996 143.749 343.051 144.008C343.106 144.267 343.255 144.496 343.47 144.65C343.709 144.763 343.982 144.782 344.235 144.704C344.487 144.626 344.702 144.456 344.836 144.228C344.97 144 345.014 143.731 344.959 143.472C344.904 143.213 344.755 142.984 344.54 142.83Z" fill="#FB6524"/>
      <path d="M346.26 143.72C346.02 143.596 345.741 143.568 345.481 143.643C345.222 143.717 345 143.888 344.862 144.121C344.724 144.353 344.68 144.63 344.739 144.894C344.798 145.157 344.956 145.389 345.18 145.54C345.42 145.664 345.699 145.692 345.958 145.617C346.218 145.543 346.44 145.372 346.578 145.139C346.716 144.907 346.76 144.63 346.701 144.366C346.641 144.103 346.484 143.871 346.26 143.72Z" fill="#FB6524"/>
      <path d="M348.14 144.32C347.901 144.207 347.628 144.188 347.375 144.266C347.123 144.344 346.908 144.514 346.774 144.742C346.64 144.97 346.596 145.239 346.651 145.498C346.706 145.757 346.855 145.986 347.07 146.14C347.309 146.253 347.582 146.272 347.835 146.194C348.087 146.116 348.302 145.946 348.436 145.718C348.57 145.49 348.614 145.221 348.559 144.962C348.504 144.703 348.355 144.474 348.14 144.32Z" fill="#FB6524"/>
      <path d="M350 144.56C349.76 144.436 349.481 144.408 349.221 144.483C348.962 144.557 348.74 144.728 348.602 144.961C348.464 145.193 348.42 145.47 348.479 145.734C348.538 145.997 348.696 146.229 348.92 146.38C349.16 146.504 349.439 146.532 349.698 146.457C349.958 146.383 350.18 146.212 350.318 145.979C350.456 145.747 350.5 145.47 350.441 145.206C350.381 144.943 350.224 144.711 350 144.56Z" fill="#FB6524"/>
      <path d="M346.84 159C347.13 160.06 352.97 167 357.58 165C359.29 164.27 358.65 158.67 358.65 158.67L360.48 148.89L348 141.54C348 141.54 346.72 158.52 346.84 159Z" fill="#D3766A"/>
      <path d="M347.57 147.88C348.634 150.257 350.176 152.39 352.099 154.147C354.022 155.903 356.286 157.245 358.75 158.09L360.48 148.85L348 141.54C348 141.54 347.82 144.41 347.57 147.88Z" fill="#263238"/>
      <path d="M369.5 133.23C372.44 149.23 363.17 152.84 359.71 153.47C356.57 154.04 345.8 155.59 341.22 139.95C336.64 124.31 343.17 117.86 350.28 116.17C357.39 114.48 366.56 117.19 369.5 133.23Z" fill="#D3766A"/>
      <path d="M364.86 131.93C364.48 131.93 364.13 131.85 363.75 131.81C363.35 131.822 362.956 131.703 362.63 131.47C362.539 131.377 362.483 131.256 362.47 131.127C362.458 130.998 362.489 130.869 362.56 130.76C362.725 130.566 362.94 130.42 363.181 130.337C363.422 130.255 363.681 130.238 363.93 130.29C364.457 130.309 364.957 130.527 365.33 130.9C365.409 130.99 365.461 131.101 365.478 131.219C365.496 131.337 365.479 131.458 365.429 131.567C365.379 131.676 365.299 131.769 365.198 131.833C365.097 131.897 364.98 131.931 364.86 131.93Z" fill="#263238"/>
      <path d="M353.88 133.18C354.243 133.086 354.6 132.973 354.95 132.84C355.335 132.765 355.685 132.568 355.95 132.28C356.018 132.172 356.049 132.045 356.036 131.918C356.023 131.791 355.968 131.672 355.88 131.58C355.678 131.421 355.437 131.319 355.182 131.284C354.927 131.248 354.668 131.282 354.43 131.38C353.923 131.512 353.482 131.825 353.19 132.26C353.126 132.366 353.095 132.488 353.101 132.612C353.108 132.735 353.15 132.854 353.225 132.953C353.299 133.052 353.401 133.126 353.518 133.167C353.634 133.207 353.761 133.212 353.88 133.18Z" fill="#263238"/>
      <path d="M356.61 137.05C356.61 137.05 356.53 137.11 356.53 137.15C356.71 138.25 356.72 139.54 355.77 140.03C355.77 140.03 355.77 140.1 355.77 140.09C357 139.77 357 138.06 356.61 137.05Z" fill="#263238"/>
      <path d="M355.48 136C353.69 136.08 353.99 139.65 355.65 139.58C357.31 139.51 357 136 355.48 136Z" fill="#263238"/>
      <path d="M354.8 136.27C354.5 136.49 354.23 136.88 353.85 136.95C353.47 137.02 353.06 136.68 352.75 136.34C352.75 136.34 352.68 136.34 352.69 136.34C352.74 137.03 353.06 137.7 353.8 137.75C354.54 137.8 354.91 137.14 355.02 136.43C355 136.35 354.91 136.17 354.8 136.27Z" fill="#263238"/>
      <path d="M363.13 136.19C363.13 136.19 363.22 136.19 363.22 136.28C363.22 137.39 363.52 138.65 364.55 138.94V139.01C363.31 138.93 363 137.25 363.13 136.19Z" fill="#263238"/>
      <path d="M364 135C365.77 134.73 366.18 138.29 364.54 138.54C362.9 138.79 362.55 135.18 364 135Z" fill="#263238"/>
      <path d="M364.8 135.1C365.1 135.26 365.42 135.57 365.8 135.55C366.18 135.53 366.44 135.12 366.64 134.72H366.7C366.8 135.4 366.7 136.13 366.02 136.34C365.34 136.55 364.93 136 364.69 135.34C364.6 135.25 364.68 135 364.8 135.1Z" fill="#263238"/>
      <path d="M357.64 146.26C357.9 146.52 358.17 146.88 358.57 146.92C358.982 146.92 359.388 146.828 359.76 146.65C359.76 146.65 359.83 146.65 359.81 146.71C359.649 146.925 359.433 147.093 359.185 147.197C358.937 147.3 358.666 147.336 358.4 147.3C358.172 147.238 357.968 147.11 357.813 146.932C357.658 146.754 357.56 146.534 357.53 146.3C357.528 146.286 357.531 146.273 357.538 146.261C357.545 146.25 357.556 146.241 357.569 146.236C357.582 146.231 357.596 146.231 357.609 146.236C357.622 146.24 357.633 146.249 357.64 146.26Z" fill="#263238"/>
      <path d="M362.08 141.8C362.239 142.603 362.356 143.414 362.43 144.23C362.43 144.3 362.25 144.35 361.99 144.37C361.324 144.523 360.63 144.492 359.981 144.279C359.332 144.065 358.754 143.678 358.31 143.16C358.25 143.09 358.31 143 358.42 143.05C359.452 143.677 360.657 143.958 361.86 143.85C361.91 143.63 361.23 141.05 361.36 141.03C361.968 141.057 362.57 141.164 363.15 141.35C362.6 137.9 361.51 134.57 361.01 131.13C361.01 131.01 361.17 130.96 361.21 131.07C362.503 134.611 363.438 138.273 364 142C364 142.43 362.4 141.92 362.08 141.8Z" fill="#263238"/>
      <path d="M361.76 144C361.391 144.653 360.861 145.201 360.22 145.59C359.86 145.786 359.448 145.866 359.04 145.82C358.15 145.72 358.04 144.94 358.04 144.25C358.077 143.882 358.157 143.519 358.28 143.17C359.321 143.814 360.541 144.105 361.76 144Z" fill="#263238"/>
      <path d="M360.22 145.58C359.86 145.776 359.448 145.856 359.04 145.81C358.15 145.71 358.04 144.93 358.04 144.24C358.503 144.173 358.974 144.271 359.372 144.516C359.771 144.761 360.071 145.137 360.22 145.58Z" fill="#FF9ABB"/>
      <path d="M357.64 120.31C358.011 121.715 357.952 123.199 357.47 124.57C355.76 128.26 351.34 127.32 349.85 128.79C348.36 130.26 348.97 132.98 347.7 134.35C345.95 136.25 343.53 135.53 343.25 136.81C342.97 138.09 343.19 140.81 341.59 141.3C339.99 141.79 331.7 116.11 353.27 115.69C355.509 115.515 357.761 115.759 359.91 116.41C366.06 118.15 368.35 128.52 368.35 128.52C367.569 128.56 366.795 128.362 366.13 127.95C365.13 127.05 364.71 126.22 363.96 125.65C362.78 124.74 362.14 125.09 360.96 124.21C359.621 123.128 358.495 121.805 357.64 120.31Z" fill="#263238"/>
      <path d="M357.31 119.14C357.647 120.189 357.861 121.272 357.95 122.37C358.096 123.439 357.951 124.527 357.53 125.52C356.984 126.423 356.133 127.101 355.13 127.43C354.13 127.8 353.03 127.92 351.95 128.36C351.397 128.587 350.914 128.956 350.55 129.43C350.241 129.927 350.037 130.482 349.95 131.06C349.839 132.126 349.441 133.142 348.8 134C348.449 134.395 348.015 134.708 347.529 134.917C347.044 135.127 346.518 135.226 345.99 135.21C344.93 135.21 343.76 134.93 342.72 135.41C343.79 135.02 344.88 135.41 345.98 135.47C346.554 135.512 347.13 135.427 347.668 135.222C348.205 135.016 348.691 134.694 349.09 134.28C349.824 133.394 350.283 132.313 350.41 131.17C350.495 130.655 350.682 130.162 350.96 129.72C351.272 129.323 351.687 129.018 352.16 128.84C353.16 128.42 354.24 128.28 355.29 127.84C355.831 127.64 356.337 127.356 356.79 127C357.262 126.656 357.64 126.198 357.89 125.67C358.3 124.6 358.407 123.437 358.2 122.31C358.022 121.223 357.724 120.16 357.31 119.14Z" fill="#263238"/>
      <path d="M357.72 120.1C357.924 121.11 358.261 122.088 358.72 123.01C359.188 123.975 359.928 124.782 360.85 125.33C361.318 125.56 361.807 125.745 362.31 125.88C362.757 126.021 363.155 126.284 363.46 126.64C363.723 127.084 364.031 127.499 364.38 127.88C364.764 128.238 365.226 128.501 365.73 128.65C365.283 128.419 364.889 128.099 364.57 127.71C364.27 127.302 364.009 126.867 363.79 126.41C363.468 125.949 363.01 125.599 362.48 125.41C362.008 125.269 361.549 125.084 361.11 124.86C360.276 124.354 359.587 123.641 359.11 122.79C358.588 121.925 358.124 121.026 357.72 120.1Z" fill="#263238"/>
      <path d="M344.47 140C344.47 140 340.21 135.48 338.11 137.08C336.01 138.68 339.74 145.58 342.57 146.18C342.927 146.282 343.302 146.311 343.67 146.265C344.039 146.219 344.395 146.099 344.716 145.912C345.037 145.726 345.317 145.476 345.54 145.178C345.762 144.881 345.922 144.541 346.01 144.18L344.47 140Z" fill="#D3766A"/>
      <path d="M339.23 139.06C339.18 139.06 339.23 139.12 339.23 139.13C341.23 139.62 342.48 141.21 343.44 142.92C343.302 142.748 343.129 142.608 342.932 142.509C342.735 142.41 342.519 142.355 342.298 142.347C342.078 142.34 341.859 142.38 341.655 142.465C341.452 142.55 341.269 142.678 341.12 142.84C341.12 142.84 341.12 142.95 341.19 142.93C341.505 142.777 341.86 142.725 342.206 142.782C342.552 142.84 342.871 143.003 343.12 143.25C343.541 143.659 343.913 144.116 344.23 144.61C344.34 144.76 344.63 144.61 344.54 144.44C344.2 142 341.84 138.92 339.23 139.06Z" fill="#263238"/>
      <path d="M365.12 164.3L354.69 192.13C354.29 192.94 354.63 193.67 355.46 193.76L370.23 195.42C370.685 195.436 371.136 195.323 371.53 195.095C371.924 194.867 372.246 194.532 372.46 194.13L382.89 166.3C383.009 166.154 383.084 165.977 383.106 165.79C383.129 165.603 383.098 165.413 383.018 165.243C382.937 165.072 382.81 164.928 382.651 164.827C382.493 164.726 382.308 164.671 382.12 164.67L367.34 163C366.885 162.985 366.435 163.099 366.042 163.329C365.649 163.559 365.329 163.896 365.12 164.3Z" fill="#37474F"/>
      <path d="M366.87 164.49L356.44 192.31C356.321 192.456 356.246 192.633 356.224 192.82C356.201 193.007 356.232 193.197 356.312 193.367C356.393 193.537 356.52 193.682 356.679 193.783C356.837 193.884 357.022 193.939 357.21 193.94L372 195.6C372.454 195.616 372.903 195.503 373.296 195.275C373.689 195.047 374.009 194.712 374.22 194.31L384.65 166.48C385.05 165.67 384.71 164.94 383.88 164.85L369.1 163.19C368.643 163.174 368.192 163.288 367.797 163.518C367.403 163.748 367.081 164.085 366.87 164.49Z" fill="#455A64"/>
      <path d="M370.78 166.25C370.748 166.499 370.643 166.734 370.477 166.923C370.312 167.113 370.094 167.249 369.851 167.315C369.608 167.38 369.352 167.372 369.113 167.292C368.875 167.211 368.666 167.061 368.513 166.862C368.36 166.662 368.269 166.422 368.253 166.171C368.237 165.92 368.295 165.67 368.421 165.452C368.547 165.234 368.735 165.059 368.961 164.948C369.187 164.838 369.441 164.796 369.69 164.83C369.855 164.851 370.014 164.904 370.159 164.987C370.303 165.07 370.43 165.181 370.531 165.313C370.632 165.445 370.706 165.596 370.749 165.756C370.792 165.917 370.802 166.085 370.78 166.25Z" fill="#263238"/>
      <path d="M312.72 232.88C319.25 233.19 326.84 227.44 346.33 210.33C349.02 207.97 337.43 184.45 334.42 186.83C330.63 189.83 316.57 202.28 315.66 203.32C308 212.1 308.29 232.67 312.72 232.88Z" fill="#D3766A"/>
      <path d="M327.64 194.87C327.64 194.87 332.83 186 336.99 182.18C341.427 178.324 346.731 175.6 352.45 174.24C358.62 172.76 374.56 173.43 375.17 178.06C375.72 182.24 359.46 180.73 359.46 180.73C359.46 180.73 376.29 179.54 376.02 184.73C375.75 189.92 357 187 357 187C357 187 374.32 186.16 373.2 191.44C372.36 195.44 355.2 193.83 355.2 193.83C355.2 193.83 370.09 193.21 369.4 197.35C368.64 201.93 352.72 201.44 352.72 201.44C349.19 211.67 343.33 215.1 338.86 217.04C337.78 217.54 327.64 194.87 327.64 194.87Z" fill="#D3766A"/>
      <path d="M353.61 180.77C355.61 180.56 357.61 180.33 359.56 180.29C361.51 180.25 363.56 180.29 365.51 180.44C368.097 180.591 370.66 181.03 373.15 181.75C368.64 180.75 361.54 180.8 359.55 180.84C357.56 180.88 355.55 180.84 353.61 180.84V180.77Z" fill="#263238"/>
      <path d="M353.61 187.94C356.14 187.78 358.61 187.52 361.18 187.47C363.75 187.42 366.11 187.6 368.63 187.72C368.82 187.72 368.76 187.83 368.56 187.83C366.04 187.83 363.7 187.98 361.17 187.99C358.64 188 356.12 187.9 353.61 187.99V187.94Z" fill="#263238"/>
      <path d="M352.5 194.09C356.375 193.747 360.276 193.803 364.14 194.26C364.33 194.26 364.23 194.4 364.03 194.4C360.16 194.26 357.13 194.13 352.52 194.22C352.45 194.22 352.42 194.1 352.5 194.09Z" fill="#263238"/>
      <path d="M309.85 218.81C310.083 214.127 311.423 209.565 313.76 205.5C318.19 199.72 325.23 194.44 330.64 189.55C332.277 186.654 334.386 184.051 336.88 181.85C339.37 179.595 342.293 177.871 345.47 176.78C342.4 178.052 339.592 179.883 337.19 182.18C334.767 184.485 332.704 187.142 331.07 190.06C325.75 194.98 318.76 200.23 314.22 205.83C311.844 209.798 310.353 214.232 309.85 218.83V218.81Z" fill="#263238"/>
      <path d="M285.68 108C286.115 112.169 287.7 116.134 290.258 119.454C292.816 122.774 296.246 125.318 300.166 126.801C304.086 128.285 308.341 128.649 312.456 127.854C316.571 127.06 320.385 125.137 323.47 122.3L334.78 124L329.12 114.22C330.539 110.777 331.075 107.033 330.68 103.33C330.056 97.3626 327.086 91.8878 322.425 88.1099C317.764 84.3319 311.793 82.5604 305.825 83.185C299.858 83.8096 294.383 86.7791 290.605 91.4403C286.827 96.1015 285.056 102.073 285.68 108.04V108Z" fill="white"/>
      <path d="M292.45 89.31C296.21 85.6475 301.117 83.3918 306.345 82.9225C311.573 82.4532 316.803 83.7989 321.155 86.7333C325.507 89.6676 328.716 94.0114 330.241 99.0339C331.767 104.056 331.515 109.451 329.53 114.31V113.92L335.16 123.72L335.58 124.45L334.76 124.32L323.45 122.58L323.72 122.5C320.577 125.348 316.702 127.263 312.53 128.03C311.497 128.222 310.45 128.329 309.4 128.35C308.353 128.405 307.304 128.385 306.26 128.29C304.173 128.091 302.123 127.603 300.17 126.84C298.218 126.102 296.38 125.092 294.71 123.84C293.872 123.228 293.077 122.56 292.33 121.84C291.596 121.099 290.912 120.31 290.28 119.48C286.889 115.06 285.24 109.548 285.646 103.991C286.051 98.4351 288.483 93.2213 292.48 89.34L292.45 89.31ZM290.31 119.4C291.561 121.064 293.062 122.525 294.76 123.73C296.423 124.972 298.259 125.963 300.21 126.67C302.156 127.391 304.192 127.838 306.26 128C307.289 128.07 308.321 128.07 309.35 128C310.382 127.954 311.408 127.827 312.42 127.62C316.48 126.806 320.231 124.874 323.25 122.04L323.36 121.94H323.52L334.84 123.53L334.44 124.12L328.74 114.36L328.64 114.18L328.72 113.97C329.974 110.845 330.517 107.481 330.31 104.12C330.087 100.754 329.106 97.483 327.44 94.55C325.745 91.6284 323.435 89.1102 320.67 87.17C317.879 85.243 314.684 83.9808 311.33 83.48C306.899 82.7986 302.366 83.4648 298.319 85.3921C294.271 87.3194 290.896 90.4187 288.632 94.2879C286.369 98.1571 285.32 102.617 285.623 107.09C285.925 111.563 287.565 115.841 290.33 119.37L290.31 119.4Z" fill="#263238"/>
      <path d="M305.69 118.53L296.02 108.75L299.27 105.55L304.91 111.26L316.64 92.88L320.48 95.33L305.69 118.53Z" fill="#FB6524"/>
      <path d="M231.16 460.56H123.38L139.1 350.77H215.45L231.16 460.56Z" fill="#37474F"/>
      <path d="M215.44 350.77L203.47 460.56H231.16L215.44 350.77Z" fill="#455A64"/>
      <path d="M160.6 358.34L157.23 357.63C157.23 357.53 159.23 348.31 161.29 339C163.55 328.94 173.45 324.31 181.72 325.64C187.91 326.64 194.77 331.47 193.72 341.79C192.61 352.79 191.72 358.04 191.72 358.26L188.32 357.71C188.32 357.71 189.18 352.37 190.27 341.45C191.06 333.52 186.07 329.84 181.13 329.04C174.44 327.96 166.43 331.67 164.61 339.75C162.57 349 160.62 358.25 160.6 358.34Z" fill="#263238"/>
      <path d="M204.93 459.72C207.14 458.14 209.44 456.72 211.73 455.25C214.02 453.78 216.36 452.45 218.73 451.11L219.05 450.92L219.35 451.14C221.17 452.51 222.99 453.88 224.76 455.32C226.53 456.76 228.31 458.18 230.01 459.71C228.01 458.53 226.15 457.27 224.27 455.98C222.39 454.69 220.52 453.39 218.68 452.05H219.29C216.96 453.42 214.59 454.74 212.21 456.05C209.83 457.36 207.4 458.59 204.93 459.72Z" fill="white"/>
      <path d="M219 451.6C218.53 444.6 218.18 437.6 217.85 430.6C217.52 423.6 217.28 416.6 217 409.6C216.72 402.6 216.56 395.6 216.41 388.6L216.22 378C216.22 374.5 216.13 371 216.14 367.48C216.39 370.98 216.56 374.48 216.78 377.98L217.3 388.49C217.63 395.49 217.86 402.49 218.14 409.49C218.42 416.49 218.58 423.49 218.72 430.49C218.86 437.49 219 444.58 219 451.6Z" fill="white"/>
      <path d="M163.49 358C163.492 358.907 163.225 359.794 162.722 360.548C162.22 361.303 161.505 361.892 160.667 362.239C159.83 362.587 158.908 362.679 158.019 362.502C157.13 362.326 156.313 361.89 155.671 361.249C155.03 360.607 154.594 359.79 154.417 358.901C154.241 358.012 154.333 357.09 154.681 356.253C155.028 355.415 155.617 354.7 156.372 354.198C157.126 353.695 158.013 353.428 158.92 353.43C160.131 353.433 161.292 353.915 162.149 354.771C163.005 355.628 163.487 356.789 163.49 358Z" fill="#263238"/>
      <path d="M194.28 358C194.282 358.907 194.015 359.794 193.512 360.548C193.01 361.303 192.294 361.892 191.457 362.239C190.62 362.587 189.698 362.679 188.809 362.502C187.919 362.326 187.102 361.89 186.461 361.249C185.82 360.607 185.384 359.79 185.207 358.901C185.031 358.012 185.123 357.09 185.47 356.253C185.818 355.415 186.407 354.7 187.162 354.198C187.916 353.695 188.803 353.428 189.71 353.43C190.921 353.433 192.082 353.915 192.938 354.771C193.795 355.628 194.277 356.789 194.28 358Z" fill="#263238"/>
      <path d="M175.96 460.56H82.21L95.88 365.06H162.29L175.96 460.56Z" fill="#FB6524"/>
      <path d="M162.29 365.06L151.87 460.56H175.96L162.29 365.06Z" fill="#FB6524"/>
      <path opacity="0.1" d="M162.29 365.06L151.87 460.56H175.96L162.29 365.06Z" fill="black"/>
      <path d="M114.59 371.65L111.65 371.03C111.65 370.95 113.37 362.92 115.19 354.83C117.19 346.07 125.76 342.05 132.95 343.21C138.34 344.08 144.31 348.28 143.41 357.21C142.41 366.82 141.71 371.35 141.68 371.54L138.68 371.06C138.68 371.01 139.42 366.42 140.38 356.92C141.07 350.02 136.72 346.82 132.38 346.12C126.57 345.18 119.6 348.41 118.02 355.44C116.3 363.56 114.6 371.57 114.59 371.65Z" fill="#263238"/>
      <path d="M153.14 459.83C155.07 458.46 157.06 457.2 159.06 455.95C161.06 454.7 163.06 453.5 165.14 452.34L165.42 452.18L165.67 452.37C167.26 453.56 168.84 454.75 170.38 456C171.92 457.25 173.47 458.49 174.95 459.82C173.25 458.82 171.59 457.7 169.95 456.58C168.31 455.46 166.69 454.32 165.09 453.16H165.63C163.63 454.35 161.54 455.5 159.46 456.62C157.38 457.74 155.29 458.85 153.14 459.83Z" fill="white"/>
      <path d="M165.38 452.76C164.97 446.67 164.66 440.58 164.38 434.49C164.1 428.4 163.89 422.3 163.64 416.2C163.39 410.1 163.26 404.01 163.13 397.91L162.96 388.76C162.96 385.71 162.88 382.66 162.89 379.61C163.11 382.61 163.26 385.7 163.45 388.74L163.9 397.88C164.19 403.98 164.39 410.07 164.64 416.17C164.89 422.27 165.02 428.36 165.14 434.46C165.26 440.56 165.38 446.66 165.38 452.76Z" fill="white"/>
      <path d="M117.1 371.34C117.1 372.131 116.865 372.904 116.426 373.562C115.986 374.22 115.362 374.733 114.631 375.035C113.9 375.338 113.096 375.417 112.32 375.263C111.544 375.109 110.831 374.728 110.272 374.168C109.712 373.609 109.331 372.896 109.177 372.12C109.022 371.344 109.102 370.54 109.404 369.809C109.707 369.078 110.22 368.454 110.878 368.014C111.535 367.575 112.309 367.34 113.1 367.34C114.161 367.34 115.178 367.761 115.928 368.512C116.679 369.262 117.1 370.279 117.1 371.34Z" fill="#263238"/>
      <path d="M143.88 371.34C143.88 372.131 143.645 372.904 143.206 373.562C142.766 374.22 142.142 374.733 141.411 375.035C140.68 375.338 139.876 375.417 139.1 375.263C138.324 375.109 137.611 374.728 137.052 374.168C136.492 373.609 136.111 372.896 135.957 372.12C135.803 371.344 135.882 370.54 136.184 369.809C136.487 369.078 137 368.454 137.658 368.014C138.316 367.575 139.089 367.34 139.88 367.34C140.941 367.34 141.958 367.761 142.708 368.512C143.459 369.262 143.88 370.279 143.88 371.34Z" fill="#263238"/>
    </svg>
  )
}

 
export const Facebook = () => {
  return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.2656 8C15.2656 3.98633 12.0137 0.734375 8 0.734375C3.98633 0.734375 0.734375 3.98633 0.734375 8C0.734375 11.6264 3.39131 14.6322 6.86475 15.1777V10.1003H5.01904V8H6.86475V6.39922C6.86475 4.57842 7.94873 3.57266 9.60898 3.57266C10.4041 3.57266 11.2355 3.71445 11.2355 3.71445V5.50156H10.3191C9.4168 5.50156 9.13525 6.06172 9.13525 6.63623V8H11.1503L10.828 10.1003H9.13525V15.1777C12.6087 14.6322 15.2656 11.6264 15.2656 8Z" fill="black"/>
      </svg>
  )
}

export const Twitter = () => {
  return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36.6526 3.8078H43.3995L28.6594 20.6548L46 43.5797H32.4225L21.7881 29.6759L9.61989 43.5797H2.86886L18.6349 25.56L2 3.8078H15.9222L25.5348 16.5165L36.6526 3.8078ZM34.2846 39.5414H38.0232L13.8908 7.63406H9.87892L34.2846 39.5414Z" fill="white"/>
      </svg>
  )
}

export const Instagram = () => {
  return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00293 4.63061C6.13965 4.63061 4.63672 6.13354 4.63672 7.99683C4.63672 9.86011 6.13965 11.363 8.00293 11.363C9.86621 11.363 11.3691 9.86011 11.3691 7.99683C11.3691 6.13354 9.86621 4.63061 8.00293 4.63061ZM8.00293 10.1853C6.79883 10.1853 5.81445 9.20386 5.81445 7.99683C5.81445 6.78979 6.7959 5.80835 8.00293 5.80835C9.20996 5.80835 10.1914 6.78979 10.1914 7.99683C10.1914 9.20386 9.20703 10.1853 8.00293 10.1853ZM12.292 4.49292C12.292 4.92944 11.9404 5.27808 11.5068 5.27808C11.0703 5.27808 10.7217 4.92651 10.7217 4.49292C10.7217 4.05933 11.0732 3.70776 11.5068 3.70776C11.9404 3.70776 12.292 4.05933 12.292 4.49292ZM14.5215 5.28979C14.4717 4.23804 14.2314 3.3064 13.4609 2.53882C12.6934 1.77124 11.7617 1.53101 10.71 1.47827C9.62598 1.41675 6.37695 1.41675 5.29297 1.47827C4.24414 1.52808 3.3125 1.76831 2.54199 2.53589C1.77148 3.30347 1.53418 4.23511 1.48145 5.28686C1.41992 6.37085 1.41992 9.61987 1.48145 10.7039C1.53125 11.7556 1.77148 12.6873 2.54199 13.4548C3.3125 14.2224 4.24121 14.4626 5.29297 14.5154C6.37695 14.5769 9.62598 14.5769 10.71 14.5154C11.7617 14.4656 12.6934 14.2253 13.4609 13.4548C14.2285 12.6873 14.4688 11.7556 14.5215 10.7039C14.583 9.61987 14.583 6.37378 14.5215 5.28979ZM13.1211 11.8669C12.8926 12.4412 12.4502 12.8835 11.873 13.115C11.0088 13.4578 8.95801 13.3787 8.00293 13.3787C7.04785 13.3787 4.99414 13.4548 4.13281 13.115C3.55859 12.8865 3.11621 12.4441 2.88477 11.8669C2.54199 11.0027 2.62109 8.9519 2.62109 7.99683C2.62109 7.04175 2.54492 4.98804 2.88477 4.12671C3.11328 3.55249 3.55566 3.11011 4.13281 2.87866C4.99707 2.53589 7.04785 2.61499 8.00293 2.61499C8.95801 2.61499 11.0117 2.53882 11.873 2.87866C12.4473 3.10718 12.8896 3.54956 13.1211 4.12671C13.4639 4.99097 13.3848 7.04175 13.3848 7.99683C13.3848 8.9519 13.4639 11.0056 13.1211 11.8669Z" fill="black"/>
      </svg>
  )
}

export const Linkedin = () => {
  return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.37539 13.6252H1.6543V4.8625H4.37539V13.6252ZM3.01338 3.66719C2.14326 3.66719 1.4375 2.94648 1.4375 2.07637C1.4375 1.65842 1.60353 1.25759 1.89906 0.962053C2.1946 0.666518 2.59543 0.500488 3.01338 0.500488C3.43133 0.500488 3.83216 0.666518 4.12769 0.962053C4.42323 1.25759 4.58926 1.65842 4.58926 2.07637C4.58926 2.94648 3.8832 3.66719 3.01338 3.66719ZM14.5596 13.6252H11.8443V9.35957C11.8443 8.34297 11.8238 7.03926 10.4296 7.03926C9.01484 7.03926 8.79805 8.14375 8.79805 9.28633V13.6252H6.07988V4.8625H8.68965V6.05781H8.72773C9.09102 5.36934 9.97842 4.64277 11.3023 4.64277C14.0562 4.64277 14.5625 6.45625 14.5625 8.81172V13.6252H14.5596Z" fill="black"/>
      </svg>
  )
}

export const Youtube = () => {
  return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.5454 3.15873C12.0471 3.29352 12.444 3.6904 12.5788 4.19211C12.8259 5.10567 12.8334 7.00019 12.8334 7.00019C12.8334 7.00019 12.8334 8.90221 12.5862 9.80828C12.4515 10.31 12.0546 10.7069 11.5529 10.8417C10.6468 11.0888 7.00002 11.0888 7.00002 11.0888C7.00002 11.0888 3.35325 11.0888 2.44718 10.8417C1.94546 10.7069 1.54859 10.31 1.4138 9.80828C1.16669 8.89472 1.16669 7.00019 1.16669 7.00019C1.16669 7.00019 1.16669 5.10567 1.40631 4.1996C1.5411 3.69789 1.93798 3.30101 2.43969 3.16622C3.34576 2.91911 6.99253 2.91162 6.99253 2.91162C6.99253 2.91162 10.6393 2.91162 11.5454 3.15873ZM8.8571 7.0002L5.83186 8.75244V5.24795L8.8571 7.0002Z" fill="black"/>
      </svg>
  )
}
export const Whatsapp = () => {
  return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48L3.374 35.674C1.292 32.066 0.198 27.976 0.2 23.782C0.206 10.67 10.876 0 23.986 0C30.348 0.002 36.32 2.48 40.812 6.976C45.302 11.472 47.774 17.448 47.772 23.804C47.766 36.918 37.096 47.588 23.986 47.588C20.006 47.586 16.084 46.588 12.61 44.692L0 48ZM13.194 40.386C16.546 42.376 19.746 43.568 23.978 43.57C34.874 43.57 43.75 34.702 43.756 23.8C43.76 12.876 34.926 4.02 23.994 4.016C13.09 4.016 4.22 12.884 4.216 23.784C4.214 28.234 5.518 31.566 7.708 35.052L5.71 42.348L13.194 40.386ZM35.968 29.458C35.82 29.21 35.424 29.062 34.828 28.764C34.234 28.466 31.312 27.028 30.766 26.83C30.222 26.632 29.826 26.532 29.428 27.128C29.032 27.722 27.892 29.062 27.546 29.458C27.2 29.854 26.852 29.904 26.258 29.606C25.664 29.308 23.748 28.682 21.478 26.656C19.712 25.08 18.518 23.134 18.172 22.538C17.826 21.944 18.136 21.622 18.432 21.326C18.7 21.06 19.026 20.632 19.324 20.284C19.626 19.94 19.724 19.692 19.924 19.294C20.122 18.898 20.024 18.55 19.874 18.252C19.724 17.956 18.536 15.03 18.042 13.84C17.558 12.682 17.068 12.838 16.704 12.82L15.564 12.8C15.168 12.8 14.524 12.948 13.98 13.544C13.436 14.14 11.9 15.576 11.9 18.502C11.9 21.428 14.03 24.254 14.326 24.65C14.624 25.046 18.516 31.05 24.478 33.624C25.896 34.236 27.004 34.602 27.866 34.876C29.29 35.328 30.586 35.264 31.61 35.112C32.752 34.942 35.126 33.674 35.622 32.286C36.118 30.896 36.118 29.706 35.968 29.458Z" fill="white"/>
      </svg>
  )
}
export const Telegram = () => {
  return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_318_75)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM24.8601 17.7179C22.5257 18.6888 17.8603 20.6984 10.8638 23.7466C9.72766 24.1984 9.13251 24.6404 9.07834 25.0726C8.98677 25.803 9.90142 26.0906 11.1469 26.4822C11.3164 26.5355 11.4919 26.5907 11.6719 26.6492C12.8973 27.0475 14.5457 27.5135 15.4026 27.5321C16.1799 27.5489 17.0475 27.2284 18.0053 26.5707C24.5423 22.158 27.9168 19.9276 28.1286 19.8795C28.2781 19.8456 28.4852 19.803 28.6255 19.9277C28.7659 20.0524 28.7521 20.2886 28.7372 20.352C28.6466 20.7383 25.0562 24.0762 23.1982 25.8036C22.619 26.3421 22.2081 26.724 22.1242 26.8113C21.936 27.0067 21.7443 27.1915 21.56 27.3692C20.4215 28.4667 19.5678 29.2896 21.6072 30.6336C22.5873 31.2794 23.3715 31.8135 24.1539 32.3463C25.0084 32.9282 25.8606 33.5085 26.9632 34.2313C27.2442 34.4155 27.5125 34.6068 27.7738 34.7931C28.7681 35.5019 29.6615 36.1388 30.7652 36.0373C31.4065 35.9782 32.0689 35.3752 32.4053 33.5767C33.2004 29.3263 34.7633 20.1169 35.1244 16.3219C35.1561 15.9895 35.1163 15.5639 35.0843 15.3771C35.0523 15.1904 34.9855 14.9242 34.7427 14.7272C34.4552 14.4939 34.0113 14.4447 33.8127 14.4482C32.91 14.4641 31.5251 14.9456 24.8601 17.7179Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_318_75">
          <rect width="48" height="48" fill="white"/>
              </clipPath>
          </defs>
      </svg>

  )
}