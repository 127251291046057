import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { reset, setArticle } from '../../../../../redux/feature/annonce/annonce.slice';
import { getPubs, reset as preset } from '../../../../../redux/feature/pub/pub.slice';
import { AlertModule, Button, ModalLayout, Textfield } from '../../../../components';
import { APIURL } from '../../../../../redux/utils';
import { slugifyFileName } from '../../../../../utils/function';

export const Categoriefield = ({subcategoryId, require, error, fnc} : {subcategoryId?: string, require?: boolean, error?: any, fnc: (val?: string | any) => void}) => {
  const [categoryId, setCategoryId] = useState<any>(null);
  const categoryState: any = useAppSelector((state) => state.category);
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const [searchS, setSearchS] = useState<string>("")
  const [openOptions, setOptions] = useState<boolean>(false)

  useEffect(() => {
    if(error){
      if("subcategoryId" === error.field){
        setErrorMessage(error.message)
      }
    }
  }, [error])

  useEffect(() => {
    if(subcategoryId && categoryState){
      setCategoryId(categoryState?.categories?.filter((el: any) => el.subcategories?.filter((ele: any) => ele._id === subcategoryId)?.length > 0)[0])
    }
  }, [subcategoryId, categoryState])


  return (
    <div className={`kubini-selectfield ${errorMessage ? "st-error" : ""}`}>
      <label className={"kubini-selectfield__label kubini-selectfield-label"} htmlFor={`kubini-input-subcategoryId`}>
        <strong className={"kubini-selectfield-label__text"}>Sous-categorie</strong>
        <div className={"kubini-selectfield__tooltip kubini-selectfield-tooltip"}>
          <i className=" icon-question-square"></i>
          <div className={"kubini-selectfield-tooltip__text"}>
            <strong>This field is {require ? "obligatory" : "optional"}</strong>
            {/* <span>{tooltip}</span> */}
          </div>
        </div>
        {
          !require && (
            <span className={"kubini-selectfield__optionnal"}>(optional)</span>
          )
        }
      </label>
      <div className={"kubini-selectfield-field"}>
        <button type={"button"} className={"kubini-selectfield-field__container"} onClick={() => setOptions(!openOptions)}>
          {
            subcategoryId ? (
              <strong>
                {categoryState?.categories?.filter((el: any) => el.subcategories?.filter((ele: any) => ele._id === subcategoryId)?.length > 0)[0]?.subcategories?.filter((ele: any) => ele._id === subcategoryId)?.length > 0 ? (
                  categoryState?.categories?.filter((el: any) => el.subcategories?.filter((ele: any) => ele._id === subcategoryId)?.length > 0)[0]?.subcategories?.filter((ele: any) => ele._id === subcategoryId)[0].name
                ) : ""}
              </strong>
            ) : (
              <span>{"Choissir une sous-catégorie"}</span>
            )
          }
        </button>
        <div className={`kubini-selectfield-field-options ${openOptions ? "is--active" : ""}`}>
          {
            categoryId ? (
              <>
                <button type='button' className='kbn-back-button' onClick={() => {setCategoryId(null); setSearchS("")}}>Revenir en arrière</button>
                <strong>Categorie: {categoryId?.name}</strong>
                <div className={"kubini-selectfield-field-options__search"}>
                  <input type="search" placeholder={"search..."} value={searchS} onChange={(e) => setSearchS(e.target.value)}/>
                </div>

                <div className={"kubini-selectfield-field-options__list"}>
                  {
                    categoryId?.subcategories?.filter((el: any) => el.name?.toLocaleLowerCase().includes(searchS.toLocaleLowerCase())).length > 0 ? (
                      categoryId?.subcategories?.filter((el: any) => el.name?.toLocaleLowerCase().includes(searchS.toLocaleLowerCase())).map((item: any, index: number) => (
                        <div className={`kubini-selectfield-field-option ${item._id === subcategoryId ? "is--active" : ""}`} key={index}>
                          <button className={"kubini-selectfield-field-option-text"} type={"button"} onClick={() => {
                            fnc(item?._id)
                            setOptions(false)
                          }}>
                            {
                              item._id === subcategoryId ? (
                                <i className="icon-validation-check-circle"></i>
                              ) : null
                            }

                            <span>{item.name}</span>
                          </button>
                          {
                            item._id === subcategoryId ? (
                              <button className={"kubini-selectfield-field-option-delete"} onClick={() => {
                                fnc("")
                              }}>
                                <i className="icon-delete-square"></i>
                              </button>
                            ) : null
                          }
                        </div>
                      ))
                    ) : (
                        <span>No items found...</span>
                    )
                  }
                </div>
              </>
            ) : (
              <>
                <div className={"kubini-selectfield-field-options__search"}>
                  <input type="search" placeholder={"search..."} value={search} onChange={(e) => setSearch(e.target.value)}/>
                </div>

                <div className={"kubini-selectfield-field-options__list"}>
                  {
                    categoryState?.categories?.filter((el: any) => el.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())).length > 0 ? (
                      categoryState?.categories?.filter((el: any) => el.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((item: any, index: number) => (
                        <div className={`kubini-selectfield-field-option ${item._id === categoryId?._id ? "is--active" : ""}`} key={index}>
                          <button className={"kubini-selectfield-field-option-text"} type={"button"} onClick={() => {
                            setCategoryId(item)
                            // setOptions(false)
                          }}>
                            {
                              item._id === categoryId?._id ? (
                                <i className="icon-validation-check-circle"></i>
                              ) : null
                            }

                            <span>{item.name}</span>
                          </button>
                          {
                            item._id === categoryId?._id ? (
                              <button className={"kubini-selectfield-field-option-delete"} onClick={() => {
                                setCategoryId(null)
                              }}>
                                <i className="icon-delete-square"></i>
                              </button>
                            ) : null
                          }
                        </div>
                      ))
                    ) : (
                        <span>No items found...</span>
                    )
                  }
                </div>
              </>
            )
          }
        </div>
      </div>

      {
        errorMessage ? (
          <div className={"kubini-selectfield__message kubini-selectfield-message"}>
            {errorMessage}
          </div>
        ) : null
      }
    </div>
  )

}

export const AnnonceCreatePage = () => {
  
  const { token, info } = useContext(UserAuthContext);
  const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  const {isCreateError, isCreateLoading, isCreateSuccess, message: aMessage} = useAppSelector((state) => state.annonce);
  const [images, setImages] = useState<any[]>([]);
  const [imagesUrl, setImagesUrl] = useState<any[]>([]);
  const [imagesPUrl, setImagesPUrl] = useState<any[]>([]);
  const [imagesPreview, setImagesPreview] = useState<any[]>([]);
  const [imagesToSend, setImagesToSend] = useState<any[]>([]);
  
  const [data, setData] = useState<any>({
    title: "", 
    price: "", 
    description: "", 
    subcategoryId: ""
  });


  const fetchPresignedUrl = async () => {
    if(images.length > 0){
      images.forEach(async (el: any) => {
        const response = await fetch(`${APIURL}presigned-url/put?folder=${`annonce-de-`+info?.username}&id=${el.name}&expiry=86400`);
        const data = await response.json();
        setImagesPUrl((list) => [...list, {url: data.url, ...el}])
      })
      
    }else{
      setError({
        type: "global",
        message: "Veuillez renseigner une image"
      })
      return
    }
  };
  
  
  const dispatch = useAppDispatch();
  
  const [error, setError] = useState<{ type: string, message: string }>({
    type: "",
    message: ""
  })
  let navigate = useNavigate()
  const [openC, setOpenC] = useState<boolean>(false);

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.ajout_article}`)
    }
  }, [token, navigate]);

  useEffect(() => {
    dispatch(getPubs({}))

    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch])

  const handleMultiChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
       let tabs: File[] = Object.values(file)
        if(tabs.length < 5){
            tabs?.forEach((el, index) => {
                let type: number = el.size
                let max: number = 1024 * 1024 * 4
                if(type < max){
                  let info = {
                    name: slugifyFileName(el.name),
                    size: el.size,
                    type: el.type
                  }
                  if(imagesUrl.filter((el: any) => el === info.name).length < 1){
                    setImagesUrl((list) => [...list, info.name])
                    setImages((list) => [...list, {name: info.name, file: el, progress: 0, status: "normal"}])
                    setImagesPreview(list => [...list, URL.createObjectURL(el)])
                  }
                }else{
                    setError({
                        type: "global",
                        message: "Votre fichier ne doit pas dépasser 4Mo"
                    })
                    return
                }
            })
            setError({
                type: "",
                message: ""
            })
        }else{
            setError({
                type: "multi-image",
                message: "Vous ne devez pas dépasser 4 images"
            })
        }
    }
  }


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.title){
        setError({
            type: "title",
            message: "Veuillez renseigner le titre"
        })
        return
    }
    if(!data.description){
        setError({
            type: "description",
            message: "Veuillez renseigner la description"
        })
        return
    }
    if(!data.subcategoryId){
        setError({
            type: "subcategoryId",
            message: "Veuillez renseigner la sous-categorie"
        })
        return
    }
    if(!data.price){
        setError({
            type: "domaine",
            message: "Veuillez choisir la catégorie"
        })
        return
    }
    if(images.length < 1){
        setError({
            type: "global",
            message: "Veuillez renseigner au moins une image de capture d'écran"
        })
        return
    }
    if(images.length > 4){
        setError({
            type: "global",
            message: "Veuillez renseigner 4 images maximum de capture d'écran"
        })
        return
    }

    // let datas = {
    //     ...data,
    //     images: images,
    // }
    setOpenC(true)
    // dispatch(setArticle(datas))
    fetchPresignedUrl()
  }

  useEffect(() => {
    if(openC){
      if(imagesPUrl.length === images.length){
        if(imagesPUrl.length > 0){
          imagesPUrl.forEach(async (el: any) => {
            const fileUrl = el?.file
            const putUrl = el?.url
            const response = await fetch(putUrl, {
              method: 'PUT',
              body: fileUrl,
            });
            if (response.ok) {
              const photoUrl = putUrl.split('?')[0];
              setImagesToSend((list) => [...list, {
                url: photoUrl,
                name: el.name,
                size: el.size,
                type: "image"
              }])
            } else {
              setError({
                type: "global",
                message: "Une erreur est survenue lors de l'enregistrement de l'images"
              })
              return
            }
          })
        }
      }else{
        setError({
          type: "global",
          message: "Une erreur est survenue"
        })
      }
    }
  }, [imagesPUrl, openC, images])

  useEffect(() => {
    if(openC){
      if(imagesToSend.length === images.length){
        dispatch(setArticle({
          ...data,
          images: imagesToSend
        }))
      }
    }
  }, [data, dispatch, openC, imagesToSend, images])

  useEffect(() => {
    if(isCreateSuccess){
      setOpenC(true)
    }
  }, [isCreateSuccess])

  if(isLoading){
    return (
      <div className={"kubini-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }

  if(isError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
        </div>
    )
  }
  
  return (
    <MainLayout title='Vendre un produit'>
      <div className='enka-home'>
        <div className='enka-home__header'>
          <h1>Vendre un produit</h1>
        </div>
        <div className='enka-home__create enka-home-create'>
          <form className='enka-home-create__container' onSubmit={handleSubmit}>
            {
              isCreateError ? (
                <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={aMessage}/>
              ) : (
                error?.type === "global" ? (
                  <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error?.message}/>
                ) : null
              )
            }

            <div className={"kbn-mulit-file"}>
              <input type="file" accept='.jpg, .png,.jpeg' multiple id='imagesImg' onChange={handleMultiChangeFile}/>
              <div className='kbn-mulit-file__grid'>
                {
                  images.length > 0 ? (
                      images.length > 3 ? (
                          images.map((image, index) => (
                              <div className={"kbn-mulit-file-preview"} key={index}>
                                  <img src={imagesPreview[index]} alt="article"/>
                                  <button className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                    setImagesUrl(list => [...list.filter(el => el !== imagesUrl[index])])
                                    setImagesPreview(list => [...list.filter(el => el !== imagesPreview[index])])
                                    setImages(list => [...list.filter(el => el !== image)])
                                  }}>
                                      <i className="icon-delete-bin"></i>
                                  </button>
                              </div>
                          ))
                      ) : (
                          <>
                              {
                                  images.map((image, index) => (
                                      <div className={"kbn-mulit-file-preview"} key={index}>
                                          <img src={imagesPreview[index]} alt="projet"/>
                                          <button className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                            setImagesUrl(list => [...list.filter(el => el !== imagesUrl[index])])
                                            setImagesPreview(list => [...list.filter(el => el !== imagesPreview[index])])
                                            setImages(list => [...list.filter(el => el !== image)])
                                          }}>
                                              <i className="icon-delete-bin"></i>
                                          </button>
                                      </div>
                                  ))
                              }
                              <label htmlFor="imagesImg" className={"kbn-mulit-file-preview_btn"}>
                                  <i className="icon-add-square"></i>
                                  <span>Vous pouvez ajouter {4 - imagesUrl.length} image(s) si vous le voulez</span>
                              </label>
                          </>
                      )
                  ) : (
                      <label htmlFor="imagesImg" className={"kbn-mulit-file-btn"}>
                          <i className=" icon-picture-gallery"></i>
                          {
                              error?.type === "multi-image" ? (
                                  <span style={{color: "red"}}>{error?.message}</span>
                              ) : (
                                  <span>Cliquer pour changer des images du produit à vendre (4 max - 4Mo max par image) </span>
                              )
                          }

                      </label>
                  )
                }
              </div>
            </div>
            <div className='enka-home-create__fields'>
              <div className='enka-home-create__field'>
                <Textfield
                    id={"title"}
                    value={data.title}
                    require={true}
                    changeValue={(val: string) => setData({...data, title: val})}
                    type={"text"}
                    label={"Titre de l'annonce"}
                    error={error}
                    placeholder={"Veuillez insérer le titre"}
                />
              </div>
              <div className='enka-home-create__field'>
                <Textfield
                    id={"price"}
                    value={data.price}
                    require={true}
                    changeValue={(val: string) => setData({...data, price: val})}
                    type={"text"}
                    label={"Prix de l'annonce"}
                    error={error}
                    placeholder={"Veuillez insérer le prix"}
                />
              </div>
              <div className='enka-home-create__field'>
                <Categoriefield require={true} subcategoryId={data?.subcategoryId} fnc={(val: string) => setData({...data, subcategoryId: val})} error={error}/>
              </div>
              <div className='enka-home-create__field'>
                <Textfield
                    id={"description"}
                    value={data.description}
                    require={true}
                    changeValue={(val: string) => setData({...data, description: val})}
                    type={"textarea"}
                    label={"Description de l'annonce"}
                    error={error}
                    placeholder={"Veuillez insérer la description"}
                />
              </div>
            </div>
            <div className='enka-home-create__action'>
              <Button btnType={"submit"} label={isCreateLoading ? "Ajout en cours..." : "Publier l'annonce"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={isCreateLoading} iconLeftClass={"icon-spin"}/>
            </div>
          </form>
          <div className='enka-home-create__side'>
            {
              isSuccess && pubs ? (
                pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                  <div  className='enka-ads is--side' >
                    <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                    <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                    <span>publicité</span>
                    </a>
                  </div>
                ) : null
              ) : null
            }
          </div>
        </div>

        <ModalLayout size={"is--md"} id={"add"} fnc={() => {}} withClose={false} isOpen={openC}>
          <div className={"kubini-modal-form-status"}>
            <div className={"kubini-modal-form-status__container"}>
                <div className={"kubini-modal-form-status__icon is--success"}>
                    <i className="icon-validation-check"/>
                </div>
                <div className={"kubini-modal-form-status__text"}>
                    <h2>Vendre un produit</h2>
                    <p>Votre annonce vient d'être publiée</p>
                    <div className={"kubini-modal-form-status__action"}>
                        <Button
                            btnType={"button"}
                            label={"Revenir dans mon compte..."}
                            btnStyle={"is--neutral"}
                            active={true}
                            withIconLeft={false}
                            onClick={() => {
                              navigate(links.account+"?tabs=annonce")
                            }}
                        />
                    </div>
                </div>
            </div>
          </div>
        </ModalLayout>
      </div>
    </MainLayout>
  )
}
