import React from 'react'
import { MainLayout } from '../../../layout'

export function MentionPage() {
  return (
    <MainLayout title='Mentions légaux'>
        <div></div>
    </MainLayout>
  )
}
