import { AccountPage, NotificationPage, ActiveAccountPage, AnnoncePage, AnnoncesPage, CartPage, DiscussionPage, ErrorPage, EventPage, EventsPage, FeedbacksPage, HomePage, LoginPage, MessageriePage, PostPage, PostsPage, RecoverPage, RegisterPage, SearchPage, SettingCustomPage, SettingInformationPage, SettingPasswordPage, UserPage, MyPostPage, PostCreatePage, PostEditPage, MyEventPage, EventEditPage, EventCreatePage, AnnonceEditPage, MyAnnoncePage, AnnonceCreatePage, TermsPage, MentionPage } from "../pages"


export const links = {
    home: "/",
    terms: "/termes",
    mention: "/mentions-legales",
    account: "/mon-compte",
    cart: "/enregistrer-pour-plus-tard",
    notif: "/boite-de-reception",
    login: "/connexion",
    register: "/creer-son-compte",
    active_account: "/compte-active/",
    recover_password: "/reinitialisation-du-mot-de-passe/",
    setting_info: "/parametres/mes-informations",
    setting_password: "/parametres/mot-de-passe",
    setting_custom: "/parametres/customisation",
    setting_feedback: "/parametres/feedbscks",

    articles: "/les-annonces",
    article: "/annonce/",
    my_article: "/mon-annonce/",
    edit_article: "/modification-annonce/",
    ajout_article: "/ajout-annonce",

    events: "/les-evenements",
    event: "/evenement/",
    my_event: "/mon-evenement/",
    edit_event: "/modification-evenement/",
    ajout_event: "/ajout-evenement",

    posts: "/les-publications",
    post: "/publication/",
    my_post: "/mon-publication/",
    edit_post: "/modification-publication/",
    ajout_post: "/ajout-publication",

    search: "/recherche",
    user: "/annonceur/",
    messagerie: "/messagerie",
    discussion: "/discussion/",
    errors: "/*",
}

export const routes = [
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.terms,
        Component: TermsPage
    },
    {
        path: links.mention,
        Component: MentionPage
    },

    {
        path: links.account,
        Component: AccountPage
    },
    {
        path: links.notif,
        Component: NotificationPage
    },
    {
        path: links.cart,
        Component: CartPage
    },
    {
        path: links.setting_info,
        Component: SettingInformationPage
    },
    {
        path: links.setting_password,
        Component: SettingPasswordPage
    },
    {
        path: links.setting_custom,
        Component: SettingCustomPage
    },
    {
        path: links.setting_feedback,
        Component: FeedbacksPage
    },
    {
        path: links.user + ":slug",
        Component: UserPage
    },
    {
        path: links.login,
        Component: LoginPage
    },
    {
        path: links.register,
        Component: RegisterPage
    },
    {
        path: links.active_account + ":username/:token",
        Component: ActiveAccountPage
    },
    {
        path: links.recover_password + ":username/:token",
        Component: RecoverPage
    },
    
    {
        path: links.articles,
        Component: AnnoncesPage
    },
    {
        path: links.ajout_article,
        Component: AnnonceCreatePage
    },
    {
        path: links.article + ":slug",
        Component: AnnoncePage
    },
    {
        path: links.my_article + ":slug",
        Component: MyAnnoncePage
    },
    {
        path: links.edit_article + ":slug",
        Component: AnnonceEditPage
    },

    {
        path: links.events,
        Component: EventsPage
    },
    {
        path: links.ajout_event,
        Component: EventCreatePage
    },
    {
        path: links.event + ":slug",
        Component: EventPage
    },
    {
        path: links.my_event + ":slug",
        Component: MyEventPage
    },
    {
        path: links.edit_event + ":slug",
        Component: EventEditPage
    },

    {
        path: links.posts,
        Component: PostsPage
    },
    {
        path: links.ajout_post,
        Component: PostCreatePage
    },
    {
        path: links.my_post + ":slug",
        Component: MyPostPage
    },
    {
        path: links.post + ":slug",
        Component: PostPage
    },
    {
        path: links.edit_post + ":slug",
        Component: PostEditPage
    },

    {
        path: links.search,
        Component: SearchPage
    },
    {
        path: links.discussion + ":slug",
        Component: DiscussionPage
    },
    {
        path: links.messagerie,
        Component: MessageriePage
    },
   
    {
        path: links.errors,
        Component: ErrorPage
    },
]